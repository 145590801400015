import $ from 'jquery'

const common = require('../app.js')
const claimsHelpers = require('../claims/claims.helper.js')
const helpers = require('../helpers')
const orderHelper = require('../orders/orders.helpers.js')
common.setInstance()

export default {
  name: 'completedOrder',
  async mounted () {
    await this.init()
  },
  data () {
    let fields = [
      { key: 'code', label: this.$language.item_code },
      { key: 'description', label: this.$language.description },
      { key: 'unit', label: this.$language.unit },
      { key: 'quantity', label: this.$language.quantity_delivered },
      { key: 'net', label: this.$language.total_net }
    ]

    let locale = common.getCustomerLocale().orders
    for (const [key, value] of Object.entries(locale.columns)) {
      if (!value) delete fields[fields.map(e => e.key).indexOf(key)]
    }

    if (common.getCustomerRole() === 'classic' || (common.getCustomerRole() === 'customerservice' && this.$route.query.Role === 'classic')) {
      // Remove price columns
      fields = fields.filter(field => field.key ? !field.key.includes('net') : true)
    }
    if (common.getCustomerLocale().claims) fields.push({ key: 'claims', label: '' })
    return {
      fields: fields,
      hasClaims: common.getCustomerLocale().claims,
      items: this.items,
      order: this.order,
      remainder: false,
      prevRoute: '',
      showItemCode: false,
      ticketItemCode: '',
      ticketOrderCode: '',
      ticketMessage: '',
      ticketSubject: '',
      ticketEmail: common.getCustomerEmail(),
      ticketName: common.getCustomerName(),
      previewImage: null,
      claimModalLabel: '',
      claimFaultTypes: [],
      modalInputRequired: false,
      customerRole: common.getCustomerRole(),
      customer_bu: common.getCustomerBusinessUnitCode(),
      hideWeight: common.getCustomerLocale().hideWeight,
      hideTotalDetail: common.getCustomerLocale().orders.hideTotalDetail,
      showTotalGross: common.getCustomerLocale().orders.columns.totalGross
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  updated () {
    this.$nextTick(function () {
      this.commentsCheckLoadMore()
    })
  },
  methods: {
    async init () {
      const ref = this.$route.params.id
      common.instance.get('/orders/' + ref).then(async response => {
        this.lines = response.data.lines
        this.order = { ...response.data.order }
        this.remainder = response.data.remainder
        this.order.OrderNextDeliveryDateTime = this.$moment(this.order.OrderNextDeliveryDateTime).format('DD/MM/YYYY')
        this.order.OrderDateTime = this.$moment(this.order.OrderDateTime).format('DD/MM/YYYY')
        this.order.comments = response.data.comment
        this.items = []
        this.itemsCodes = []
        this.showSelect = true
        this.lines.forEach((line) => {
          this.items.push({
            code: line.ItemCode,
            description: line.ItemDescription,
            unit: line.UnitOfMeasureCode,
            quantity: line.Quantity,
            net: helpers.formatPrice(line.TotalDiscountPrice),
            claims: this.$language.make_a_query
          })
          this.itemsCodes.push(line.ItemCode)
        })
        // If is a customer service, the mail of the customer (not the cs) is send for a ticket
        if (common.isCustomerService(this.$route.query.BU, this.$route.query.CC, common.getCustomerRole())) {
          await this.setClientClaimsMail()
        }
      })
    },
    dropdown (type) {
      common.toggleClasses($('.dropdown-' + type), $('.' + type + '-chevron-up'), $('.' + type + '-chevron-down'))
    },
    redirectToPrevPage (path) {
      this.$router.push({ path: path })
    },
    sendItemCode (itemCode) {
      this.showItemCode = true
      this.ticketItemCode = itemCode
    },
    formatPriceWithCurrency (price) {
      return helpers.formatPriceWithCurrency(price)
    },
    hideItemCode () {
      this.ticketItemCode = ''
      this.showItemCode = false
    },
    loadModalTitleAndFaultTypes (orderOrItem) {
      let businessUnit = common.getCustomerBusinessUnitCode()
      if (orderOrItem === 'order') {
        this.claimFaultTypes = businessUnit === 'FRA' ? claimsHelpers.frenchOrderFaultTypes : (businessUnit === 'ITA' ? claimsHelpers.italianOrderItemFaultTypes : claimsHelpers.orderFaultTypes)
        this.claimModalLabel = this.$language.log_an_order_issue
      } else {
        this.claimFaultTypes = businessUnit === 'FRA' ? claimsHelpers.frenchItemFaultTypes : (businessUnit === 'ITA' ? claimsHelpers.italianOrderItemFaultTypes : claimsHelpers.itemFaultTypes)
        this.claimModalLabel = this.$language.log_an_item_or_product_issue
      }
    },
    uploadImage (event) {
      const image = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = event => {
        this.previewImage = event.target.result
      }
    },
    onChangeOption (event) {
      if (event.target.value === 'Incorrect - Price/Invoice/Discount' || event.target.value === 'Request to Restock - Not Required') {
        this.modalInputRequired = true
      } else {
        this.modalInputRequired = false
      }
    },
    commentsCheckLoadMore () {
      if (this.$refs.commentsDiv && this.$refs.commentsDiv.scrollHeight === this.$refs.commentsDiv.clientHeight) {
        this.$refs.commentsMoreDiv.remove()
      }

      if (this.$refs.commentsDivMobile && this.$refs.commentsDivMobile.scrollHeight === this.$refs.commentsDivMobile.clientHeight) {
        this.$refs.commentsMoreDivMobile.remove()
      }
    },
    viewMoreComments () {
      this.$refs.commentsDivMobile.classList = []
      this.$refs.commentsDiv.classList = []
      this.$refs.commentsMoreDiv.remove()
      this.$refs.commentsMoreDivMobile.remove()
    },
    postTicket () {
      if (this.modalInputRequired && !this.ticketMessage) {
        alert('Please add any supporting notes')
      } else {
        const emailWhoToSend = common.isCustomerService(this.$route.query.BU, this.$route.query.CC, common.getCustomerRole()) ? this.clientClaimsMail : common.getCustomerEmail()
        common.instance.get('/me').then(response => {
          let alukRef = common.getCustomerBusinessUnitCode() === 'ITA' ? this.order.OrderName : this.order.OrderCode
          let ticketMessage = this.$language.this_ticket_concerns_the_order + ' ' + alukRef + '.\n'
          ticketMessage += '"' + this.ticketMessage + '"'
          common.instance.post('/claims/post', {
            params: {
              'alert': true,
              'autorespond': true,
              'message': ticketMessage,
              'subject': this.ticketSubject,
              'name': common.getCustomerEmail(),
              // topicId is linked to department -- The value '2' is for Italian department
              'topicId': common.getCustomerBusinessUnitCode() === 'FRA' ? '1' : '2',
              'email': emailWhoToSend,
              'aluk_customer-code': common.getCustomerCustomerCode(),
              'aluk_company-name': response.data.customer.CustomerName,
              'aluk_number': alukRef,
              'aluk_item-code': this.ticketItemCode,
              'attachments': [
                { 'image.png': this.previewImage }
              ]
            }
          }).then(res => {
            $('#claim-successfull-creation').addClass('show')
            $('#addClaim').modal('hide')
            this.ticketMessage = ''
            this.ticketSubject = ''
          })
        })
      }
    },
    async setClientClaimsMail () {
      let token = await common.getUsersToken()
      await common.instance.get(`/customers-service/byCustomerCode/${this.$route.query.BU}/${this.$route.query.CC}`, { params: { 'token': token.data.token } }).then(response => {
        this.clientClaimsMail = common.getCustomerEmail()
        if (response.data[0]) {
          if (response.data[0].users[0]) {
            this.clientClaimsMail = response.data[0].users[0].mail
          }
        }
      })
    },
    ...orderHelper.setDetailOrdersMethods()
  },
  watch: {
    ...orderHelper.setDetailOrdersWatch()
  }
}

import { fieldNotifications } from '../fields.js'
const common = require('../app.js')

let itemNotification = []

export default {
  name: 'search-notifications',
  props: {
    research: String
  },
  mounted () {
    this.searchNotifications(this.research)
    this.field = fieldNotifications
  },
  data () {
    return {
      items: {
        notifications: itemNotification,
        field: fieldNotifications
      }
    }
  },
  methods: {
    // Redirige vers la page de notifications avec la notification de séléctionnée
    onRowNotificationsClicked (notification) {
      this.$router.push({ path: '/notifications/' + notification.notificationRef })
    },

    // Fonction appelée lors du $emit "starSearch" dans searchBar.js
    // Lance un appel vers l'api pour recevoir une liste d'orders
    // value: valeur du text de la searchBar
    async searchNotifications (value) {
      let url = 'notifications/search/' + value.trim() + '/0'
      let userRole = common.getCustomerRole()
      if (['customerservice', 'sales'].includes(userRole)) {
        url += '?businessUnitCode=' + this.$route.query.bu + '&customerCode=' + this.$route.query.cc
      }
      this.items.notifications = await common.getApiCall(url, 'notifications')
    }
  },
  watch: {
    // Dès changement de valeur de 'research' => lance une recherche
    research () {
      this.searchNotifications(this.research)
    }
  }
}

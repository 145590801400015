import moment from 'moment'
import language from './lang'

let common = require('./app')

const processCodes = {
  '1': 'New order',
  '2': 'OrderNextDeliveryDate provided',
  '3': 'OrderNextDeliveryDate changed',
  '4': 'CustomerContact changed'
}

export function formatNotification (notification, items) {
  // Define notification type
  notification.NotificationType = processCodes[notification.ProcessKey]

  // Define changed value type (String, Integer, Date, Boolean)
  let changedValueType = getNotifChangedValue(notification)

  // Define notification message
  notification.Content = {
    'message1': getNotifMessage(notification, changedValueType).message1,
    'message2': getNotifMessage(notification, changedValueType).message2
  }
  notification.Title = getNotifMessage(notification, changedValueType).title

  // Define previous and current value messages
  notification.PreviousValue = language.previous_value + ': ' + notification['PreviousValue' + changedValueType]

  common.objectPush(
    items,
    ['key', 'userNotifKey', 'date', 'alukNumber', 'title', 'content', 'previousValue', 'currentValue', 'type', 'IsNotified'],
    [notification.NotificationKey, notification.UserNotificationKey, moment(notification.CreationDate).format('DD/MM/YYYY HH:mm'), notification.ContextCode, notification.Title, notification.Content, notification.PreviousValue, notification.CurrentValue, notification.NotificationType, notification.IsNotified]
  )
  return items
}

export function getNotifChangedValue (notification) {
  for (let [notificationElement, notificationElementValue] of Object.entries(notification)) {
    if (notificationElement.indexOf('PreviousValue') !== -1 &&
      notification['CurrentValue' + notificationElement.substring('PreviousValue'.length)] !== notificationElementValue) {
      return notificationElement.substring('PreviousValue'.length)
    }
  }
  return null
}

export function getNotifMessage (notification, changedValueType) {
  let title = ''
  let message1 = ''
  let message2 = ''
  switch (notification.ProcessKey) {
    case '1':
    case 1: // New order:
      title = language.your_order_num_has_been_placed_1 + notification.ContextCode + ' ' + language.your_order_num_has_been_placed_2
      message1 = title + '.'
      message2 = language.the_estimate_completion_delivery_date_for_your_order_is + ':'
      notification.CurrentValue = moment(notification.OrderHeader.OrderNextDeliveryDateTime).format('DD/MM/YYYY')
      break
    case '3':
    case 3: // OrderNextDeliveryDate changed
      title = language.the_delivery_date_has_changed + ' | ' + language.order_num + notification.ContextCode
      message1 = language.the_estimate_completion_delivery_date_for_your_order_num_has_changed_1 + notification.ContextCode + ' | ' + language.customer_reference + ' ' + notification.CustomerCode + ' ' + language.initially_planned_for_the_date_has_changed_1 + ' ' + moment(notification['PreviousValue' + changedValueType]).format('DD/MM/YYYY') + ' ' + language.initially_planned_for_the_date_has_changed_2 + ':'
      notification.CurrentValue = moment(notification['CurrentValue' + changedValueType]).format('DD/MM/YYYY')
      break
    case '4':
    case 4: // CustomerContact changed
      title = language.new_customer_service_contact
      message1 = language.you_have_a_new_customer_service_contact + ':'
      message2 = ''
  }
  return { 'title': title, 'message1': message1, 'message2': message2 }
}

export function formatPrice (price) {
  let lang = navigator.language || navigator.userLanguage
  return new Intl.NumberFormat(lang, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price)
}

export function formatPriceWithCurrency (price) {
  let lang = navigator.language || navigator.userLanguage
  return new Intl.NumberFormat(
    lang, {
      style: 'currency',
      currency: common.getCustomerLocale().currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  ).format(price)
}

export function formatColor (color) {
  if (color === 'NOT APPLICABLE') { return '' }
  return color
}

export function formatLength (length) {
  if (length === 1) { return '' }
  if (common.getCustomerLocale().orders.convertLength) {
    length = length / 1000
  }
  return length
}

export function revertFormatPrice (val) {
  let lang = navigator.language || navigator.userLanguage
  let group = new Intl.NumberFormat(lang).format(1111).replace(/1/g, '')
  let decimal = new Intl.NumberFormat(lang).format(1.1).replace(/1/g, '')
  let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
  return Number.isNaN(reversedVal) ? 0 : reversedVal
}

// Object of colors and locales for status order
const statusColorAndLocale = {
  'claim': {
    'Acknowledged': { 'color': 'color-yellow-completed', 'locale': 'claim_acknowledged' },
    'Approved': { 'color': 'color-yellow-completed', 'locale': 'claim_approved' },
    'Closed': { 'color': 'color-green-completed', 'locale': 'claim_closed' },
    'Credit Approval': { 'color': 'color-yellow-completed', 'locale': 'claim_credit_approval' },
    'For collection': { 'color': 'color-yellow-completed', 'locale': 'claim_for_collection' },
    'In Review': { 'color': 'color-yellow-completed', 'locale': 'claim_in_review' },
    'On-Hold': { 'color': 'color-yellow-completed', 'locale': 'claim_on_hold' },
    'Open': { 'color': 'color-yellow-completed', 'locale': 'claim_open' },
    'Rejected': { 'color': 'color-purple-completed', 'locale': 'claim_rejected' },
    'Resolved': { 'color': 'color-green-completed', 'locale': 'claim_resolved' },
    'Envoyée': { 'color': 'color-yellow-completed', 'locale': 'claim_acknowledged' },
    'En cours de traitement': { 'color': 'color-yellow-completed', 'locale': 'claim_in_review' },
    'Résolue': { 'color': 'color-green-completed', 'locale': 'claim_resolved' },
    'Envoyée | Aperto': { 'color': 'color-yellow-completed', 'locale': 'claim_acknowledged' },
    'En cours de traitement | In corso': { 'color': 'color-yellow-completed', 'locale': 'claim_in_review' },
    'Résolue | Chiuso': { 'color': 'color-green-completed', 'locale': 'claim_resolved' }
  },
  'order': {
    'Delayed': { 'color': 'color-purple-completed', 'locale': 'order_delayed' },
    'Delivered': { 'color': 'color-green-completed', 'locale': 'order_delivered' },
    'Closed': { 'color': 'color-green-completed', 'locale': 'order_closed' },
    'In Progress': { 'color': 'color-yellow-completed', 'locale': 'order_in_progress' },
    'On Hold': { 'color': 'color-yellow-completed', 'locale': 'order_on_hold' },
    'Opened': { 'color': 'color-yellow-completed', 'locale': 'order_opened' }
  },
  'orderStatus': {
    'Closed': { 'color': 'color-green-completed', 'locale': 'order_status_closed' },
    'Delivered': { 'color': 'color-green-completed', 'locale': 'order_status_delivered' },
    'In Progress': { 'color': 'color-yellow-completed', 'locale': 'order_status_in_progress' }
  }
}

export function transformClassStatus (type, items, cell, variantCell = null) {
  for (let index = 0; index < items.length; index++) {
    // Save and restore orderStatusReasonCode field to keep transform
    // working after a 'view more' call
    if (!('orderStatusOriginal' in items[index])) {
      items[index].orderStatusOriginal = items[index].orderStatusReasonCode
    } else {
      items[index].orderStatusReasonCode = items[index].orderStatusOriginal
    }
    // Change cell color
    items[index]._cellVariants = { [variantCell || cell]: statusColorAndLocale[type][items[index][cell]]['color'] }
    // Translate cell content
    items[index].orderStatusReasonCode = language[statusColorAndLocale[type][items[index][cell]]['locale']]
  }
  return items
}

export function transformOrdersPrice (items) {
  for (let index = 0; index < items.length; index++) {
    if (!items[index].totalGrossFormated) {
      items[index].totalGross = formatPrice(items[index].totalGross)
      items[index].totalGrossFormated = true
    }
  }
  return items
}

export function replaceAlukRefByOrderNameField (orderLinesFields) {
  const index = orderLinesFields.findIndex(field => field.key === 'alukRef')
  const fieldWithOrderName = { ...orderLinesFields[index], key: 'orderName' }
  orderLinesFields.splice(index, 1, fieldWithOrderName)
}

let defaultLocalStorageValues = {
  orderActiveKey: 'orderDate',
  orderActiveOrderBy: 'OrderDateTime',
  orderActiveSort: 'desc',
  orderCompletedKey: 'orderDate',
  orderCompletedOrderBy: 'OrderDateTime',
  orderCompletedSort: 'desc',
  orderLineKey: 'estimatedCompletionDate',
  orderLineOrderBy: 'EstimatedDeliveryDate',
  orderLineSort: 'desc',
  searchKey: 'deliveryDate',
  searchOrderBy: 'OrderNextDeliveryDateTime',
  searchSort: 'desc'
}

export function setLocalStorage (key, value) {
  if (typeof value !== 'undefined') {
    localStorage[key] = value
  }
}

export function GetLocalStorage (key) {
  let value = ''
  if (localStorage[key]) {
    value = localStorage[key]
  } else if (localStorage.token && JSON.parse(localStorage.token).idTokenClaims) {
    value = defaultLocalStorageValues[key]
  }
  return value
}

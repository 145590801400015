import Menu from '@/components/Menu.vue'

const common = require('../app.js')
common.setInstance()

export default {
  name: 'client',
  components: {
    Menu
  },
  async created () {
    this.token = await common.getUsersToken()
    await this.fetchClients(this.token, this)
  },
  data () {
    return {
      timeout: null,
      visible: false,
      clientsPerPage: 50,
      items: {
        all: []
      },
      offset: 0,
      query: '',
      token: null,
      viewMoreButtonVisible: false
    }
  },
  methods: {
    viewMore () {
      this.offset += this.clientsPerPage
      this.fetchClients(this.token, this)
    },
    async fetchClients (token, self) {
      common.instance.get('/customers-service/all', {
        params: {
          'token': token.data.token,
          'offset': self.offset,
          'query': self.query
        }
      }).then(response => {
        self.items.all = self.items.all.concat(response.data)
        self.viewMoreButtonVisible = self.items.all.length % self.clientsPerPage <= 0
      })
    }
  },
  mounted () {
    this.$root.$on('searchClient', async (value) => {
      this.query = value
      this.offset = 0
      this.items.all = []
      this.viewMoreButtonVisible = false
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.fetchClients(this.token, this)
      }, 300)
    })
  },
  beforeDestroy () {
    this.$root.$off('searchClient')
  },
  watch: {
    $route () {
      this.where = this.$route.params.where
    }
  }
}

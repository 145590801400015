const common = require('../app.js')
common.setInstance()

export default {
  name: 'userInformations',
  mounted () {
    common.instance.get('/me').then(response => {
      let customer = response.data.customer
      console.log(response.data)
      let customerRole = common.getCustomerRole()
      this.formData = {
        name: common.getCustomerName(),
        businessName: customer.CustomerName,
        businessAddressLine1: customer.AdresseLine1,
        businessAddressLine2: customer.AdresseLine2,
        businessAddressLine3: customer.AdresseLine3,
        city: customer.City,
        postCode: customer.PostCode,
        email: common.getCustomerEmail(),
        isCustomerService: customerRole === 'customerservice' || customerRole === 'sales'
      }
    })
  },
  data () {
    return {
      formData: {
        name: '',
        businessName: '',
        businessAddressLine1: '',
        businessAddressLine2: '',
        businessAddressLine3: '',
        city: '',
        postCode: '',
        email: ''
      }
    }
  }
}

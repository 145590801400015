import $ from 'jquery'

export default {
  name: 'SearchBarClaims',
  props: {
    content: String
  },
  data () {
    return {
      search: ''
    }
  },
  mounted () {
    if (this.content) {
      this.search = this.content
    }
  },
  methods: {
    cleanSearch () {
      this.search = ''
      $('.claimLine').show()
    },
    searchClaims () {
      let inputSearch = this.search.toLowerCase()
      if (inputSearch === '') {
        this.cleanSearch()
        return
      }
      $('.claimLine').each(function () {
        let ref = $(this).find('.ref').text().toLowerCase()
        let code = $(this).find('.code').text().toLowerCase()
        let alukNumber = $(this).find('.alukNumber').text().toLowerCase()
        if (!ref.includes(inputSearch.toLowerCase()) && !code.includes(inputSearch.toLowerCase()) && !alukNumber.includes(inputSearch.toLowerCase())) {
          $(this).hide()
        } else {
          $(this).show()
        }
      })
    }
  },
  computed: {
    isempty () {
      return this.search.length > 0
    }
  }
}

// @ is an alias to /src
import Menu from '@/components/Menu.vue'
const common = require('./app.js')

export default {
  name: 'menu-tab',
  components: {
    Menu
  },
  async beforeMount () {
    let businessUnitCode = common.getCustomerBusinessUnitCode(); let customerCode = common.getCustomerCustomerCode()
    await common.instance.get('/customerscontacts/BU/' + businessUnitCode + '/CC/' + customerCode).then(async response => {
      this.customerContacts = response.data.object
      this.customerContacts.forEach(contact => {
        if (contact.ContactCategoryCode === 'Primary') contact.title = this.$language.primary_contact
        if (contact.ContactCategoryCode === 'Secondary') contact.title = this.$language.secondary_contact
        switch (contact.ContactCategoryCode) {
          case 'Primary':
          case 'Secondary':
            this.items.contacts.customerService.push(contact)
            break
          case 'Academy':
            this.items.contacts.training.push(contact)
            break
          case 'Finance':
            this.items.contacts.financial.push(contact)
            break
          case 'Marketing':
            this.items.contacts.marketing.push(contact)
            break
          case 'Technical':
            this.items.contacts.technicalServices.push(contact)
            break
          case 'Sales1':
          case 'Sales2':
          case 'Sales3':
            this.items.contacts.sales.push(contact)
            break
        }
      })
    }).catch(error => {
      console.log('Error in customerscontacts :', error)
    })
  },
  data () {
    return {
      customerContacts: {},
      items: {
        contacts: {
          customerService: [],
          sales: [],
          technicalServices: [],
          financial: [],
          training: [],
          marketing: []
        }
      }
    }
  }
}

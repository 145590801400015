// @ is an alias to /src
import Menu from '@/components/Menu.vue'

export default {
  name: 'menu-tab',
  components: {
    Menu
  },
  created () {
    this.items.currentUserRole = this.$AuthService.getUser().idToken.extension_Role
    this.items.isCustomerService = this.$AuthService.getUser().idToken.extension_Role === 'customerservice' || this.$AuthService.getUser().idToken.extension_Role === 'sales'
  },
  data () {
    return {
      items: {
        currentUserRole: '',
        isCustomerService: false
      }
    }
  }
}

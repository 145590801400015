import { fieldsClaims } from '../fields.js'
const claimsHelper = require('./claims.helper')
const common = require('../app.js')
common.setInstance()

let orders = []
let items = []

export default {
  name: 'activeClaims',
  async mounted () {
    common.instance.get('/orders/latest/active/100').then(response => {
      this.orders = response.data.response
      claimsHelper.getClaims('active', this.setItems)
    })
  },
  data () {
    return {
      items: items,
      fields: fieldsClaims,
      orders: orders,
      ticketOrderCode: '',
      ticketSubject: '',
      ticketMessage: '',
      previewImage: null
    }
  },
  methods: claimsHelper.setClaimsMethods()
}

const common = require('../app.js')
common.setInstance()

export default {
  name: 'order-service',
  props: {
    customerervice: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      company: '',
      customerName: '',
      customerRole: '',
      customerCode: '',
      customerMail: ''
    }
  },
  mounted () {
    this.getCustomerContactInfos()
  },
  methods: {
    getCustomerContactInfos () {
      let businessUnitCode = this.$route.query.BU
      let customerCode = this.$route.query.CC
      common.instance.get('/customers/BU/' + businessUnitCode + '/CC/' + customerCode).then(response => {
        this.customerName = response.data.object[0].CustomerName
        this.customerCode = response.data.object[0].CustomerCode
        this.customerMail = response.data.object[0].Email
      })
    },
    async getAzureUser () {
      let token = await common.getUsersToken()
      await common.instance.get('/azuread/users/listing', { params: { 'token': token.data.token } }).then(response => {
        response.data.result.value.forEach(user => {
          if (user.otherMails[0] === this.customerMail && user.displayName === this.customerName) {
            this.getUserRole(token, user)
          }
        })
      })
    },
    async getUserRole (token, user) {
      await common.instance.get('/azuread/user/role/' + user.id, { params: { 'token': token.data.token } }).then(response => {
        this.customerRole = response.data.result.extension_dc0c4a4b7144400baeb9139145e5760e_Role
      })
    }
  }
}

import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=addf61ec"
import script from "../script/contacts.js?vue&type=script&lang=js&external"
export * from "../script/contacts.js?vue&type=script&lang=js&external"
import style0 from "../less/contacts.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue'
import { fieldOrderLines, fieldsClaims } from './fields.js'
import language from './lang'
import { revertFormatPrice } from './helpers'
import { bootstrap, setOptions } from 'vue-gtag'
import SwitchOrderToOrderLines from '@/components/SwitchOrderToOrderLines.vue'

const common = require('./app.js')
const helpers = require('./helpers.js')
const claimsHelper = require('./claims/claims.helper')
let alukEnv = 'development'
if (process.env.NODE_ENV === 'production') {
  alukEnv = 'production'
}
common.setInstance()

let itemActiveOrderLines = []
let itemCompletedOrderLines = []
let itemActiveOrders = []
let itemCompletedOrders = []
let itemActiveClaims = []

let fieldsActiveOrder = [
  { key: 'ref', label: language.aluk_reference, tdClass: 'align-middle', thClass: 'align-middle', sortable: true },
  { key: 'customerRef', label: language.customer_reference, tdClass: 'align-middle', thClass: 'align-middle', sortable: true },
  { key: 'orderDate', label: language.order_date, tdClass: 'align-middle', thClass: 'align-middle', sortable: true },
  { key: 'totalGross', label: language.total_gross, tdClass: 'align-middle text-right', thClass: 'align-middle', sortable: true },
  { key: 'deliveryDate', label: language.estimated_completion_date, tdClass: 'align-middle', thClass: 'align-middle', sortable: true },
  { key: 'orderStatusReasonCode', label: language.estimated_delivery_status, tdClass: 'align-middle ', thClass: 'align-middle', sortable: true }
]

let fieldsActiveOrderFR = [ ...fieldsActiveOrder, { key: 'remainder', label: language.remainder, tdClass: 'align-middle ', thClass: 'align-middle', sortable: true }
]

let fieldsCompletedOrder = [
  { key: 'ref', label: language.aluk_reference, tdClass: 'align-middle', thClass: 'align-middle', sortable: true },
  { key: 'customerRef', label: language.customer_reference, tdClass: 'align-middle', thClass: 'align-middle', sortable: true },
  { key: 'orderDate', label: language.order_date, tdClass: 'align-middle', thClass: 'align-middle', sortable: true }
]

let fieldsCompletedOrderFR = [ ...fieldsCompletedOrder, { key: 'orderStatusReasonCode', label: language.order_status, tdClass: 'align-middle', thClass: 'align-middle', thAttr: { orderBy: 'OrderStatusReasonCode', sort: 'asc' }, sortable: true }
]

export default {
  name: 'dashboard',
  components: {
    SearchBar,
    SwitchOrderToOrderLines
  },
  beforeMount () {
    if (common.getCustomerRole() === 'customerservice' || common.getCustomerRole() === 'sales') {
      this.$router.push({ path: '/customer-service/my' })
    }
  },
  async mounted () {
    // Set items
    [{
      url: 'active',
      object: 'activeOrders',
      type: 'order'
    },
    {
      url: 'completed',
      object: 'completedOrders',
      type: 'orderStatus'
    }
    ].forEach(async order => {
      await common.getApiCall(`/orders/${order.url}/orderBy/OrderDateTime/4/desc/0`, order.object).then((data) => {
        this.items[order.object] = helpers.transformClassStatus(order.type, data, 'orderStatusReasonCode')
      })
    })

    await common.instance.get('/notifications/latest/1').then(response => {
      if (response.data.notifications.length > 0) {
        this.items.notification = helpers.formatNotification(response.data.notifications[0].Notification, [])[0]
        this.notificationOrder = this.items.notification.alukNumber
      } else {
        this.items.notification = []
        this.notificationOrder = ''
      }
    }).catch((err) => {
      throw new Error('Error in getting notifications, details:' + err)
    })
    if (common.getCustomerLocale().claims) {
      await claimsHelper.getClaims('active', this.setItems)
    }
    // Set Google Analytics ID
    setOptions({
      config: { id: common.getCustomerLocale().analytics[alukEnv] }
    })
    bootstrap().then((gtag) => { })

    this.customerContact = await common.getContact(this.$route, 'Primary')
    this.items.activeOrders.forEach((order) => {
      order.totalGross = helpers.formatPrice(order.totalGross)
    })
    await this.getActiveOrderLines()
    await this.getCompletedOrderLines()
    if (common.getCustomerBusinessUnitCode() === 'ITA') {
      helpers.replaceAlukRefByOrderNameField(this.items.fieldOrderLines)
    }
  },
  data () {
    if (common.getCustomerRole() === 'classic' || !common.getCustomerLocale().orders.columns.totalGross) {
      // Remove price columns
      fieldsActiveOrder = fieldsActiveOrder.filter(field => field.key ? !field.key.includes('Gross') : true)
      fieldsActiveOrderFR = fieldsActiveOrderFR.filter(field => field.key ? !field.key.includes('Gross') : true)
    } else {
      let currency = common.getCustomerLocale().currency === 'GBP' ? '£' : '€'
      fieldsActiveOrder[3].label = language.total_gross + ' (' + currency + ')'
      fieldsActiveOrderFR[3].label = language.total_gross + ' (' + currency + ')'
    }
    return {
      hasClaims: common.getCustomerLocale().claims,
      urlActiveOrder: '/#/orders/active',
      urlCompletedOrder: '/#/orders/completed',
      urlActiveClaims: '/#/claims/active',
      customer_name: common.getCustomerName(),
      notificationOrder: '',
      customerContact: {},
      customer_bu: common.getCustomerBusinessUnitCode(),
      items: {
        activeOrders: itemActiveOrders,
        fieldActiveOrders: common.getCustomerBusinessUnitCode() !== 'FRA' ? fieldsActiveOrder : fieldsActiveOrderFR,
        completedOrders: itemCompletedOrders,
        fieldCompletedOrders: common.getCustomerBusinessUnitCode() !== 'FRA' ? fieldsCompletedOrder : fieldsCompletedOrderFR,
        activeClaims: itemActiveClaims,
        fieldClaims: fieldsClaims,
        notification: '',
        activeOrderLines: itemActiveOrderLines,
        completedOrderLines: itemCompletedOrderLines,
        fieldOrderLines: fieldOrderLines
      },
      activeOrderChecked: false,
      completedOrderChecked: false
    }
  },
  methods: {
    onActiveOrdersClicked (order) {
      this.$router.push({ path: '/orders/active/' + order.orderCode })
    },
    onOrderLineClicked (orderLine) {
      this.$router.push({ path: '/orders/active/' + orderLine.alukRef })
    },
    onCompletedOrdersClicked (order) {
      this.$router.push({ path: '/orders/completed/' + order.orderCode })
    },
    redirectToOrder (order) {
      this.$router.push({ path: '/orders/active/' + order })
    },
    redirectAllNotif () {
      this.$router.push({ path: '/notifications' })
    },
    redirectToNotif (notification) {
      this.$router.push({ name: 'detailNotification', params: { notification: notification } })
    },
    login () {
      this.$AuthService.login()
    },
    logout () {
      this.$AuthService.logout()
    },
    setItems (data) {
      this.items.activeClaims = data.slice(0, 4)
    },
    mySortCompare (itemA, itemB, key) {
      if (key === 'orderDate' || key === 'deliveryDate' || key === 'date') {
        let a = itemA[key]
        let b = itemB[key]
        a = a.split('/')
        b = b.split('/')
        a = common.transformDate(a)
        b = common.transformDate(b)
        return a - b
      } else if (key === 'totalGross') {
        return revertFormatPrice(itemA[key]) - revertFormatPrice(itemB[key])
      } else {
        return false
      }
    },
    handleActiveOrderCheckChange (value) {
      this.activeOrderChecked = value
    },
    handleCompletedOrderCheckChange (value) {
      this.completedOrderChecked = value
    },
    async getOrderLines (status) {
      return common.getApiCall(`order-lines/latest/${status}/4/0`, 'orderLines')
    },
    async getActiveOrderLines () {
      this.items.activeOrderLines = await this.getOrderLines('active')
    },
    async getCompletedOrderLines () {
      this.items.completedOrderLines = await this.getOrderLines('completed')
    },
    onRowOrderLineClicked (orderLine) {
      this.$router.push({
        path: '/orders/active/' + orderLine.alukRef
      })
    }
  },
  computed: {
    user: function () {
      return this.$AuthService.getUser()
    }
  }
}

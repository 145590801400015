import * as Msal from 'msal'

export default class AuthService {
  constructor () {
    let alukEnv = 'production'
    let redirectUri = 'https://dev.aluk.siliga.fr/'
    if (alukEnv === 'dev' || alukEnv === 'uat' || alukEnv === 'production') {
      redirectUri = 'https://myaluk.com/'
    }
    this.applicationConfig = {
      auth: {
        clientId: 'fbd2974a-4a2e-429b-ae90-063abccb30b0',
        redirectUri,
        authority: 'https://AlukB2BPlatformAD.b2clogin.com/AlukB2BPlatformAD.onmicrosoft.com/B2C_1_SIGNIN2',
        validateAuthority: false
      }
    }

    try {
      this.app = new Msal.UserAgentApplication(this.applicationConfig)
    } catch (error) {
      window.location.href = redirectUri
    }
  }

  login () {
    // Not working properly (need two redirection for be ok)
    function authCallback (error, response) {
      console.log(error)
      localStorage.token = JSON.stringify(response)
    }

    const loginRequest = {
      scopes: ['https://graph.microsoft.com/User.ReadWrite']
    }
    this.app.handleRedirectCallback(authCallback)
    this.app.loginRedirect(loginRequest)
  }

  logout () {
    this.app._user = null
    this.app.logout()
  }

  getUser () {
    return this.app.getAccount()
  }
}

const app = require('./app')
const locales = require('../config/locales')

let language = null

export function setLanguage (targetLanguage) {
  if (typeof targetLanguage !== 'undefined') {
    localStorage.language = targetLanguage
    location.reload()
  } else {
    let userLanguage = getUserLanguage()
    language = require('../assets/locales/AluK_BtoB_platform_' + userLanguage)
  }
}

export function getUserLanguage () {
  let userLanguage = 'English'
  if (localStorage.language) {
    userLanguage = localStorage.language
  } else if (localStorage.token && JSON.parse(localStorage.token).idTokenClaims) {
    userLanguage = locales[app.getCustomerBusinessUnitCode()].language[0]
  }
  return userLanguage
}

setLanguage()

export default language

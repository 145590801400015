// @ is an alias to /src
import Header from '@/components/Header.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import moment from 'moment'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vue from 'vue'
import language from './lang'
import $ from 'jquery'

const helpers = require('./helpers')
const locales = require('../config/locales')

const tokenProvider = require('axios-token-interceptor')
const axios = require('axios')

export const instance = axios.create({
  baseURL: '/api'
})

setInstance()

export function setInstance () {
  instance.interceptors.request.use(
    tokenProvider({
      getToken: () => {
        return getRawIdToken()
      }
    })
  )
}

export function getUsersToken () {
  return instance.get('/tokens/oauth2/users')
    .catch(error => {
      return this.cb(error)
    })
}

window.$ = require('jquery')
window.JQuery = require('jquery')

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    Vue.prototype.$AuthService.logout()
  }
})

export default {
  name: 'header-aluk',
  components: { Header, FooterMobile }
}

export function getRawIdToken () {
  let token = JSON.parse(localStorage.token)
  return token.idToken.rawIdToken
}

export function getCustomerName () {
  return JSON.parse(localStorage.token).idTokenClaims.given_name + ' ' + JSON.parse(localStorage.token).idTokenClaims.family_name
}

export function getCustomerRole () {
  return JSON.parse(localStorage.token).idTokenClaims.extension_Role
}

export function getCustomerId () {
  return JSON.parse(localStorage.token).idTokenClaims.oid
}

export function getCustomerEmail () {
  return JSON.parse(localStorage.token).idTokenClaims.emails[0]
}

export function getCustomerCustomerCode () {
  return JSON.parse(localStorage.token).idTokenClaims.extension_CustomerCode
}

export function getCustomerBusinessUnitCode () {
  return JSON.parse(localStorage.token).idTokenClaims.extension_BusinessUnitCode
}

export function getCustomerLocale () {
  return locales[getCustomerBusinessUnitCode()]
}

export function getLocalesLabels () {
  return locales['LOCALES_LABEL']
}

export function getLocaleShortLabel (language) {
  return locales['LOCALES_SHORT_LABEL'][language]
}

export async function getApiCall (route, object = '') {
  var response = []
  await instance.get(route).then(responseCall => {
    const tabFetch = {
      'activeOrders': this.fetchActiveOrders,
      'completedOrders': this.fetchCompletedOrders,
      'customerContacts': this.fetchContacts,
      'notifications': this.fetchNotifications,
      'activeClaims': this.fetchActiveClaims,
      'orderLines': this.fetchOrderLines
    }
    response = responseCall.data.response
    if (tabFetch[object]) {
      response = tabFetch[object](responseCall.data.response)
    }
  })
  return response
}

export function objectPush (object, names, values) {
  if (names.length === values.length) {
    let tab = {}
    for (let index = 0; index < names.length; index++) {
      tab[names[index]] = values[index]
    }
    object.push(tab)
  }
}

export function fetchActiveOrders (data) {
  let orders = []
  let dates = ['OrderDateTime', 'OrderNextDeliveryDateTime']
  data.forEach((order) => {
    let refValue = getCustomerBusinessUnitCode() !== 'FRA' ? order.OrderName : order.OrderCode
    dates.forEach(date => {
      if (order[date].startsWith('9999')) {
        order[date] = ''
      } else {
        order[date] = moment(order[date]).format('DD/MM/YYYY')
      }
    })
    objectPush(
      orders,
      ['ref', 'customerRef', 'orderDate', 'deliveryDate', 'totalGross', 'orderStatusReasonCode', 'remainder', 'orderCode'],
      [refValue, order.CustomerReference, order.OrderDateTime, order.OrderNextDeliveryDateTime, getCustomerBusinessUnitCode() !== 'FRA' ? order.OrderAmountBeforeTaxAndDiscount : order.OrderToBeInvoicedAmount, order.OrderStatusReasonCode, order.Remainder && order.Remainder.IsRemainder === 'Y' ? language.yes : language.no, order.OrderCode]
    )
  })
  return orders
}

export function fetchCompletedOrders (data) {
  let orders = []
  data.forEach((order) => {
    let refValue = getCustomerBusinessUnitCode() !== 'FRA' ? order.OrderName : order.OrderCode
    if (order['OrderDateTime'].startsWith('9999')) {
      order['OrderDateTime'] = ''
    } else {
      order['OrderDateTime'] = moment(order['OrderDateTime']).format('DD/MM/YYYY')
    }
    objectPush(
      orders,
      ['ref', 'customerRef', 'orderDate', 'completedDate', 'deliveryNote', 'invoices', 'claims', 'orderStatusReasonCode', 'remainder', 'orderCode'],
      [refValue, order.CustomerReference, order.OrderDateTime, '', '', '', language.make_a_query, order.OrderStatusReasonCode, order.Remainder && order.Remainder.IsRemainder === 'Y' ? language.yes : language.no, order.OrderCode]
    )
  })
  return orders
}

export function fetchContacts (data) {
  let contacts = []
  data.forEach((contact) => {
    objectPush(
      contacts,
      ['alukNumber', 'name', 'email', 'phone', 'mobile'],
      [contact.CustomerCode, contact.CustomerName, contact.Email, contact.Phone1, contact.Phone2]
    )
  })
  return contacts
}

export function fetchNotifications (data) {
  let notifications = []
  data.forEach((notification) => {
    if (notification.Notification) {
      notification = notification.Notification
    }
    objectPush(
      notifications,
      ['notificationRef', 'alukRef', 'date'],
      [notification.NotificationKey, notification.ContextCode, moment(notification.CreationDate).format('DD/MM/YYYY')]
    )
  })
  return notifications
}

export async function sortOrders (field, status, route, successCallback) {
  let orderBy = field.thAttr.orderBy
  let sort = field.thAttr.sort
  let items = []
  let url = '/orders/' + status + '/orderBy/' + orderBy + '/100/' + sort + '/0'
  if (isCustomerService(route.query.BU, route.query.CC, getCustomerRole())) {
    url += '/BU/' + route.query.BU + '/CC/' + route.query.CC
  }
  await instance.get(url).then(response => {
    if (status === 'active') {
      items = fetchActiveOrders(response.data.response)
    } else {
      items = fetchCompletedOrders(response.data.response)
    }
    if (sort === 'asc') {
      field.thAttr.sort = 'desc'
    } else {
      field.thAttr.sort = 'asc'
    }
    let result = {
      'items': items,
      'field': field
    }
    successCallback(result)
  })
}

export function redirect (router, url, item) {
  let path = url + item.orderCode
  if (item.isCustomerService) {
    path += '/?BU=' + item.route.businessUnitCode
    path += '&CC=' + item.route.customerCode
    if (item.route.role) path += '&Role=' + item.route.role
  }
  router.push({ path: path })
}

export function toggleClasses (classDropdwon, classChevronsUp, classChevronDown) {
  classDropdwon.toggleClass('dropdown-hide')
  classDropdwon.toggleClass('dropdown-show')
  classChevronsUp.toggle('hide')
  classChevronDown.toggle('hide')
}

export async function postTicket () {
  let ticketMessage = 'This ticket concerns the order ' + this.ticketOrderCode + '. ' + this.ticketMessage
  await instance.post('/claims/post', {
    params: {
      'alert': false,
      'autorespond': true,
      'message': ticketMessage,
      'subject': this.ticketSubject,
      'email': getCustomerEmail(),
      'name': getCustomerName(),
      'ip': '',
      'attachments': [
        { 'image.png': this.previewImage }
      ]
    }
  })
}

export function isCustomerService (buRoute, ccRoute, role) {
  return (buRoute !== undefined && ccRoute !== undefined && (role === 'customerservice' || role === 'sales'))
}

export async function getContact (route, contactType) {
  // If customerService access, we need to load businessUnitCode and customerCode of required account
  let isCS = isCustomerService(route.query.BU, route.query.CC, getCustomerRole())
  let businessUnitCode = isCS ? route.query.BU : getCustomerBusinessUnitCode()
  let customerCode = isCS ? route.query.CC : getCustomerCustomerCode()
  let contact = {}
  await instance.get('/customerscontacts/BU/' + businessUnitCode + '/CC/' + customerCode).then(async response => {
    if (response.data.object.length > 0 && response.data.object.find(obj => { return obj.ContactCategoryCode === contactType })) {
      contact = response.data.object.find(obj => { return obj.ContactCategoryCode === contactType })
    }
  }).catch(error => {
    console.log('Error in customerscontacts :', error)
  })
  return contact
}

export function transformDate (date) {
  return (parseInt(date[2], 10) * 10000) + (parseInt(date[1], 10) * 100) + parseInt(date[0])
}

// We have to delegate the listener because elements don't exit when the listener is created
$('body').on('click', '.alert .close', function () {
  // On alert's 'close' click, hide the alert (in case a second call is made to show the alert again)
  $(this).parent('.alert').removeClass('show')
})

export function fetchOrderLines (data) {
  let orderLines = []
  data.forEach((orderLine) => {
    if (orderLine.OrderLine) {
      orderLine = orderLine.OrderLine
    }
    objectPush(
      orderLines,
      ['code', 'description', 'quantity', 'total', 'alukRef', 'customerRef', 'orderDate', 'estimatedCompletionDate', 'orderName'],
      [orderLine.ItemCode, orderLine.ItemDescription, orderLine.Quantity, helpers.formatPrice(orderLine.TotalDiscountPrice), orderLine.OrderCode, orderLine.CustomerReference, moment(orderLine.OrderDateTime).format('DD/MM/YYYY'), moment(orderLine.OrderNextDeliveryDateTime).format('DD/MM/YYYY'), orderLine.OrderName]
    )
  })
  return orderLines
}

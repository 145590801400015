import { fieldOrderLines } from '../fields.js'
import { setView } from '../switchView'
import { GetLocalStorage, setLocalStorage } from '@/script/helpers'
const common = require('../app.js')
const helpers = require('../helpers.js')
let itemOrderLine = []

export default {
  name: 'search-orderline',
  props: {
    research: String,
    checked: Boolean,
    type: String
  },
  mounted () {
    this.viewMoreButtonIsVisible()
    if (this.research) {
      this.searchOrderLines(this.research)
    } else {
      if (this.$router.currentRoute.name.includes('active' || 'searchActiveOrders')) {
        this.getActiveOrderLines()
      }
      if (this.$router.currentRoute.name.includes('completed' || 'searchCompletedOrders')) {
        this.getCompletedOrderLines()
      }
    }
    this.field = fieldOrderLines
    if (common.getCustomerBusinessUnitCode() === 'ITA') {
      helpers.replaceAlukRefByOrderNameField(fieldOrderLines)
    }
  },
  data () {
    return {
      customer_bu: common.getCustomerBusinessUnitCode(),
      items: {
        orderLines: itemOrderLine,
        field: fieldOrderLines
      },
      orderLinesPerPage: 50,
      offset: 0,
      viewMoreButtonVisible: true,
      orderBy: GetLocalStorage('orderLineOrderBy'),
      sort: GetLocalStorage('orderLineSort'),
      sortBy: GetLocalStorage('orderLineKey'),
      sortDesc: GetLocalStorage('orderLineSort') === 'desc',
      noLocalSorting: true
    }
  },
  methods: {
    // Redirige vers la page de commandes avec la commande de séléctionnée
    onRowOrderLineClicked (orderLine) {
      this.$router.push({
        path: '/orders/active/' + orderLine.alukRef
      })
    },
    // Fonction appelée lors du $emit "starSearch" dans searchBar.js
    // Lance un appel vers l'api pour recevoir une liste d'OrderLines
    // value: valeur du text de la searchBar
    async searchOrderLines (value) {
      if (value !== undefined) {
        let url = ''
        if (this.$router.currentRoute.name.includes('searchActiveOrders')) {
          url = 'order-lines/search/' + this.type + '/' + value.trim() + '/' + this.orderLinesPerPage + '/' + this.offset + '/orderBy/' + this.orderBy + '/' + this.sort
        } else {
          url = 'order-lines/search/' + this.type + '/' + value.trim() + '/' + this.orderLinesPerPage + '/' + this.offset + '/orderBy/' + this.orderBy + '/' + this.sort
        }
        let userRole = common.getCustomerRole()
        if (['customerservice', 'sales'].includes(userRole)) {
          url += '?businessUnitCode=' + this.$route.query.bu + '&customerCode=' + this.$route.query.cc
        }
        this.items.orderLines = await common.getApiCall(url, 'orderLines')
        this.handleCheckChange()
        this.viewMoreButtonIsVisible()
      }
    },
    async onHeaderOrderLineClicked (key, field) {
      if (field.thAttr) {
        this.offset = 0
        this.sort = field.thAttr.sort
        this.orderBy = field.thAttr.orderBy
        setLocalStorage('orderLineKey', field.thAttr.key)
        setLocalStorage('orderLineOrderBy', field.thAttr.orderBy)
        setLocalStorage('orderLineSort', field.thAttr.sort)
        if (this.research) {
          await this.searchOrderLines(this.research)
        } else {
          if (this.$router.currentRoute.name.includes('active' || 'searchActiveOrders')) {
            await this.getActiveOrderLines()
          }
          if (this.$router.currentRoute.name.includes('completed' || 'searchCompletedOrders')) {
            await this.getCompletedOrderLines()
          }
        }
        field.thAttr.sort = this.sort === 'asc' ? 'desc' : 'asc'
      }
    },
    async getOrderLines (status) {
      let url = 'order-lines/search/' + status + '/*/' + this.orderLinesPerPage + '/' + this.offset + '/orderBy/' + this.orderBy + '/' + this.sort
      this.items.orderLines = await common.getApiCall(url, 'orderLines')
    },
    async getActiveOrderLines () {
      await this.getOrderLines('active')
    },
    async getCompletedOrderLines () {
      await this.getOrderLines('completed')
    },
    async makeCallAndSetFrontVariation (status) {
      let url = 'order-lines/search/' + status + '/*/' + this.orderLinesPerPage + '/' + this.offset + '/orderBy/' + this.orderBy + '/' + this.sort
      common.instance.get(url).then(response => {
        this.items.orderLines = this.items.orderLines.concat(common.fetchOrderLines(response.data.response))
        this.viewMoreButtonIsVisible()
      })
    },
    async viewMore () {
      this.offset += this.orderLinesPerPage
      if (this.$router.currentRoute.name.includes('active' || 'searchActiveOrders')) {
        await this.makeCallAndSetFrontVariation('active')
      }
      if (this.$router.currentRoute.name.includes('completed' || 'searchCompletedOrders')) {
        await this.makeCallAndSetFrontVariation('completed')
      }
    },
    viewMoreButtonIsVisible () {
      this.viewMoreButtonVisible = this.items.orderLines.length % this.orderLinesPerPage <= 0
    },
    // Fait passer en vue commande s'il n'y a pas d'articles
    // dans la réponse de la recherche effectuée
    handleCheckChange () {
      if (this.items.orderLines.length === 0) {
        setView('Orders')
        this.$root.$emit('handleCheckChange', false)
      }
    }
  },
  watch: {
    // Dès changement de valeur de 'research' => lance une recherche
    research () {
      this.searchOrderLines(this.research)
    },
    type () {
      this.searchOrderLines(this.research)
    }
  }
}

const switchView = require('../switchView')

export default {
  name: 'SwitchOrderToOrderLines',
  async beforeMount () {
    if (!this.$router.currentRoute.name.includes('search')) {
      this.checked = switchView.getView() === 'Articles'
    }
  },
  async created () {
    this.$root.$on('handleCheckChange', (checkChange) => {
      if (this.checked !== checkChange) {
        this.checked = checkChange
        this.changeState()
      }
    })
    if (this.$router.currentRoute.name.includes('search') || this.$router.currentRoute.name.includes('dashboard')) {
      this.mainDivClass = ''
    } else {
      this.mainDivClass = 'col-md-10 col-sm-12 mt-3 mb-2 ml-auto mr-auto'
    }
  },
  async mounted () {
    this.changeState()
  },
  data () {
    return {
      checked: false,
      mainDivClass: ''
    }
  },
  methods: {
    changeState () {
      if (this.checked) {
        switchView.setView('Articles')
        this.$emit('check-changed', true)
      } else {
        switchView.setView('Orders')
        this.$emit('check-changed', false)
      }
      this.$root.$emit('check-changed', this.checked)
    }
  }
}

import Menu from '@/components/Menu.vue'
import MyClients from '../../views/customer-service/MyClients.vue'
import Client from '../../views/customer-service/Client.vue'
const common = require('../app.js')
common.setInstance()

export default {
  name: 'my-clients',
  components: {
    Menu,
    Client,
    MyClients
  },
  data () {
    return {
      where: '',
      items: {
        my: {}
      },
      visible: false
    }
  },
  async created () {
    this.where = this.$route.params.where
    let token = await common.getUsersToken()
    common.instance.get('/customers-service/my', { params: { 'email': common.getCustomerEmail(), 'token': token.data.token } }).then(response => {
      this.items.my = response.data
    })
  },
  watch: {
    $route () {
      this.where = this.$route.params.where
    }
  }
}

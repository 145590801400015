import { redirect, sortOrders } from '../app'
import { setLocalStorage, transformClassStatus, transformOrdersPrice } from '../helpers'
const common = require('../app.js')
common.setInstance()

export function setOrdersMethods (status) {
  return {
    onRowClicked (item) {
      item.isCustomerService = common.isCustomerService(this.$route.query.BU, this.$route.query.CC, common.getCustomerRole())
      item.route = {}
      item.route.businessUnitCode = item.isCustomerService ? this.$route.query.BU : common.getCustomerBusinessUnitCode()
      item.route.customerCode = item.isCustomerService ? this.$route.query.CC : common.getCustomerCustomerCode()
      if (item.isCustomerService) item.route.role = this.$route.query.Role
      redirect(this.$router, '/orders/' + status + '/', item)
    },
    async onHeaderClicked (key, field) {
      let context = this
      context.sort = field.thAttr.sort
      context.orderBy = field.thAttr.orderBy
      if (status === 'active') {
        setLocalStorage('orderActiveKey', field.thAttr.key)
        setLocalStorage('orderActiveOrderBy', context.orderBy)
        setLocalStorage('orderActiveSort', context.sort)
      } else {
        setLocalStorage('orderCompletedKey', field.thAttr.key)
        setLocalStorage('orderCompletedOrderBy', context.orderBy)
        setLocalStorage('orderCompletedSort', context.sort)
      }
      await sortOrders(field, status, this.$route, function (response) {
        setContextParams(context, field, response)
      })
    },
    setOrderCode (orderCode) {
      this.ticketOrderCode = orderCode
    },
    async getOrders (url) {
      makeCallAndSetFrontVariations(url, this, status)
    }
  }
}

export function setDetailOrdersMethods () {
  return {
    /**
     * @param {*} remainder
     * @param {enum 'first' | 'remainder'} direction 'first' Go to first command, 'remainder' Go to last command
     */
    redirectToOrigine (remainder, direction) {
      // Set the last parent between OrderCodeRemainder if is set and FirstOrderCode
      const firstParent = remainder.FirstOrderCode
      const lastRemainder = remainder.OrderCodeRemainder.replace(/\s/g, '')
      const who = direction === 'first' ? firstParent : lastRemainder
      try {
        common.instance.get('/orders/' + who).then(response => {
          if (response.data) {
            const orderParent = response.data.order
            let status = 'active'
            if (orderParent.OrderStatusReasonCode === 'Closed') {
              status = 'completed'
            }
            let item = {}
            item.ref = ''
            item.isCustomerService = common.isCustomerService(this.$route.query.BU, this.$route.query.CC, common.getCustomerRole())
            item.route = {}
            item.route.businessUnitCode = item.isCustomerService ? this.$route.query.BU : common.getCustomerBusinessUnitCode()
            item.route.customerCode = item.isCustomerService ? this.$route.query.CC : common.getCustomerCustomerCode()
            if (item.isCustomerService) item.route.role = this.$route.query.Role
            redirect(this.$router, '/orders/' + status + '/' + who, item)
          }
        })
      } catch (error) {
        console.log(`error`, error)
      }
    }
  }
}

export function setDetailOrdersWatch () {
  return {
    '$route' (to, from) {
      if (to.name === from.name) {
        this.init()
      }
    }
  }
}

export function setContextParams (context, field, response) {
  context.items = response['items']
  field = response['field']
  context.viewMoreButtonVisible = true
  if (context.items.length % context.ordersPerPage > 0) {
    context.viewMoreButtonVisible = false
  }
  context.offset = 0
  response.items = transformClassStatus('order', response.items, 'orderStatusReasonCode')
  response.items = transformOrdersPrice(response.items)
}

export function buildApiUrl (type, context) {
  return '/orders/' + type + '/orderBy/' + context.orderBy + '/' + context.ordersPerPage + '/' + context.sort + '/' + context.offset
}

export function makeCallAndSetFrontVariations (url, self, status, transformStatus, type, transformPrice) {
  common.instance.get(url).then(response => {
    if (status === 'active') {
      self.items = self.items.concat(common.fetchActiveOrders(response.data.response))
    } else {
      self.items = self.items.concat(common.fetchCompletedOrders(response.data.response))
    }
    self.viewMoreButtonVisible = self.items.length % self.ordersPerPage <= 0
    if (self.items.length === 0) {
      self.messageNoOrderVisible = true
    }
    if (type && transformStatus) self.items = transformClassStatus(type, self.items, 'orderStatusReasonCode')
    else if (transformStatus) self.items = transformClassStatus('order', self.items, 'orderStatusReasonCode')
    if (transformPrice) self.items = transformOrdersPrice(self.items)
  })
}

import { fieldsActiveOrder, fieldsActiveOrderFR } from '../fields.js'
import SearchBar from '@/components/SearchBar.vue'
import SwitchOrderToOrderLines from '@/components/SwitchOrderToOrderLines.vue'
import language from '../lang'
import SearchOrderLines from '@/views/search/SearchOrderLines.vue'
import { GetLocalStorage } from '@/script/helpers'
const ordersHelpers = require('./orders.helpers.js')
const common = require('../app.js')
common.setInstance()
let items = []

export default {
  name: 'activeOrder',
  components: {
    SearchBar,
    SearchOrderLines,
    SwitchOrderToOrderLines
  },
  async mounted () {
    items.splice(0, items.length)
    let csBU = this.$route.query.BU
    let csCC = this.$route.query.CC
    let csRole = this.$route.query.Role ? this.$route.query.Role : ''
    let url = ordersHelpers.buildApiUrl('active', this)
    if (csBU !== undefined && csCC !== undefined) {
      url += '/BU/' + csBU + '/CC/' + csCC
    }
    this.customerContact = await common.getContact(this.$route, 'Technical')
    if (this.$AuthService.getUser().idTokenClaims.extension_Role === 'classic' ||
      csRole === 'classic' ||
      !common.getCustomerLocale().orders.columns.totalGross
    ) {
      common.getCustomerBusinessUnitCode() === 'FRA' ? delete fieldsActiveOrderFR[3] : delete fieldsActiveOrder[3]
    } else {
      let currency = common.getCustomerLocale().currency === 'GBP' ? '£' : '€'
      common.getCustomerBusinessUnitCode() === 'FRA'
        ? fieldsActiveOrderFR[3].label = language.total_gross + ' (' + currency + ')'
        : fieldsActiveOrder[3].label = language.total_gross + ' (' + currency + ')'
    }
    await getActiveOrders(url, this)
  },
  data () {
    return {
      fields: common.getCustomerBusinessUnitCode() === 'FRA' ? fieldsActiveOrderFR : fieldsActiveOrder,
      items: items,
      customerContact: {},
      ordersPerPage: 100,
      offset: 0,
      orderBy: GetLocalStorage('orderActiveOrderBy'),
      sort: GetLocalStorage('orderActiveSort'),
      sortBy: GetLocalStorage('orderActiveKey'),
      sortDesc: GetLocalStorage('orderActiveSort') === 'desc',
      noLocalSorting: true,
      viewMoreButtonVisible: true,
      messageNoOrderVisible: false,
      checked: false
    }
  },
  methods: {
    ...ordersHelpers.setOrdersMethods('active'),
    viewMore () {
      this.offset += this.ordersPerPage
      let url = ordersHelpers.buildApiUrl('active', this)
      let businessUnitCode = this.$route.query.BU
      let customerCode = this.$route.query.CC
      if (businessUnitCode !== undefined && customerCode !== undefined) {
        url += '/BU/' + businessUnitCode + '/CC/' + customerCode
      }
      getActiveOrders(url, this)
    },
    handleCheckChange (value) {
      this.checked = value
    }
  }
}

async function getActiveOrders (url, self) {
  ordersHelpers.makeCallAndSetFrontVariations(url, self, 'active', true, 'order', true)
}

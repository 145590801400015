const common = require('./app.js')
const language = require('./lang.js')
common.setInstance()

export default {
  name: 'headerApp',
  data () {
    return {
      version: '0.11.2',
      customer_name: common.getCustomerName(),
      customer_role: common.getCustomerRole(),
      customer_bu: common.getCustomerBusinessUnitCode(),
      hasClaims: common.getCustomerLocale().claims,
      notificationUnread: 0,
      show: true,
      contact: '',
      viewLanguages: common.getCustomerLocale().language,
      labelLanguages: common.getLocalesLabels(),
      userLanguage: common.getLocaleShortLabel(language.getUserLanguage())
    }
  },
  methods: {
    changeLanguage (lang) {
      language.setLanguage(lang)
    },
    logout () {
      this.$AuthService.logout()
    },
    redirectHome () {
      if (this.customer_role === 'customerservice' || this.customer_role === 'sales') {
        this.$router.push({ path: '/customer-service/my' })
      } else {
        this.$router.push('/home')
      }
    },
    async updateNotificationUnread () {
      let url = 'notifications/unread'
      await common.instance.get(url).then(response => {
        this.notificationUnread = response.data.notifications
      })
    }
  },
  mounted () {
    this.updateNotificationUnread()
    // Add event listener to update the notificationUnread
    this.$root.$on('reduceNotificationUnread', () => {
      this.notificationUnread--
    })

    this.$root.$on('addNotificationUnread', () => {
      this.notificationUnread++
    })

    if (this.customer_role === 'customerservice' || this.customer_role === 'sales' || this.$route.query.contact !== undefined) {
      this.show = false
    }
  },
  watch: {
    $route () {
      this.updateNotificationUnread()
    }
  }
}

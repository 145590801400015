import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Orders from '../views/orders/Orders.vue'
import ActiveOrders from '../views/orders/ActiveOrders.vue'
import CompletedOrders from '../views/orders/CompletedOrders.vue'
import DetailActiveOrders from '../views/orders/DetailActiveOrders.vue'
import DetailCompletedOrders from '../views/orders/DetailCompletedOrders.vue'
import User from '../views/user/User.vue'
import UserInformation from '../views/user/UserInformation.vue'
import UserSettings from '../views/user/UserSettings.vue'
import Contact from '../views/Contacts'
import Members from '../views/Members'
import Organization from '../views/Organization'
import Claims from '../views/claims/Claims.vue'
import ActiveClaims from '../views/claims/ActiveClaims.vue'
import CompletedClaims from '../views/claims/CompletedClaims.vue'
import DetailActiveClaims from '../views/claims/DetailActiveClaims.vue'
import DetailCompletedClaims from '../views/claims/DetailCompletedClaims.vue'
import Notifications from '../views/notifications/Notifications'
import DetailNotifications from '../views/notifications/DetailNotifications'
import Search from '../views/search/Search'
import CustomerService from '../views/customer-service/CustomerService'

Vue.use(VueRouter)

const routes = []
routes.push(setRoute(['/', 'default', '', 'dashboard']))
routes.push(setRoute(['/home', 'dashboard', Dashboard]))
routes.push(setRoute(['/contacts', 'contacts', Contact]))
routes.push(setRoute(['/notifications/:selected?', 'notification', Notifications]))
routes.push(setRoute(['/notifications/detail', 'detailNotification', DetailNotifications]))
// My Organization page for client users
routes.push(setRoute(['/members', 'members', Members]))
// My Organization page for aluk users
routes.push(setRoute(['/organization', 'organization', Organization]))
routes.push(setRoute(['/customer-service/:where?', 'customerService', CustomerService]))

let searchOrdersPaths = [
  ['/activeOrders', 'searchActiveOrders', { searchActiveOrders: Search }],
  ['/completedOrders', 'searchCompletedOrders', { searchCompletedOrders: Search }]
]
let searchOthersPaths = [
  ['/notifications', 'searchNotifications', { searchNotifications: Search }],
  ['/claims', 'searchClaims', { searchClaims: Search }],
  ['/order-lines', 'searchOrderLines', { searchOrderLines: Search }]
]
let searchPaths = searchOrdersPaths.concat(searchOthersPaths)
let childrenSearch = searchPaths.map(setChildrenRoute)
routes.push(setRoute(['/search', 'search', Search, '', childrenSearch, true]))

let ordersNames = ['active', 'completed', 'detailActiveOrder', 'detailCompletedOrder']
let ordersComponents = [
  { active: ActiveOrders },
  { completed: CompletedOrders },
  { active: DetailActiveOrders },
  { completed: DetailCompletedOrders }
]
let ordersPaths = setPaths(ordersNames, ordersComponents)

let childrenOrders = ordersPaths.map(setChildrenRoute)
routes.push(setRoute(['/orders', 'orders', Orders, 'active', childrenOrders]))

let userPaths = [
  ['/account', 'account', { account: UserInformation }],
  ['/settings', 'settings', { settings: UserSettings }]
]
let childrenUser = userPaths.map(setChildrenRoute)
routes.push(setRoute(['/user', 'user', User, 'account', childrenUser]))

let claimsNames = ['activeClaims', 'completedClaims', 'detailActiveClaims', 'detailCompletedClaims']
let claimsComponents = [
  { activeClaims: ActiveClaims },
  { completedClaims: CompletedClaims },
  { activeClaims: DetailActiveClaims },
  { completedClaims: DetailCompletedClaims }
]
let claimsPaths = setPaths(claimsNames, claimsComponents)

let childrenClaims = claimsPaths.map(setChildrenRoute)
routes.push(setRoute(['/claims', 'claims', Claims, 'activeClaims', childrenClaims]))

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const user = Vue.prototype.$AuthService.getUser()
  if (user) {
    next()
  } else {
    Vue.prototype.$AuthService.login()
  }
})

function setRoute (data) {
  let route = {}
  let params = {
    path: data[0],
    name: data[1],
    component: data[2],
    redirect: data[3],
    children: data[4],
    props: data[5]
  }
  Object.entries(params).forEach(element => {
    if (element[1] && element[1] !== '') {
      if (element[0] === 'redirect') {
        route[element[0]] = {
          name: element[1]
        }
      } else {
        route[element[0]] = element[1]
      }
      setChildPath(element[0], params)
    }
  })
  return route
}

function setChildrenRoute (data) {
  let route = {}
  route.path = data[0]
  route.name = data[1]
  route.components = data[2]
  return route
}

function setChildPath (element, params) {
  if (element === 'children') {
    params.children.forEach(child => {
      child.path = params.path + child.path
    })
  }
}

function setPaths (names, components) {
  return [
    ['/active', names[0], components[0]],
    ['/completed', names[1], components[1]],
    ['/active/:id', names[2], components[2]],
    ['/completed/:id', names[3], components[3]]
  ]
}

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle, faDraftingCompass, faArrowRight, faArrowLeft, faArrowCircleRight, faPhone, faClock, faMobile, faSignOutAlt, faPlus, faChevronRight, faChevronUp, faChevronDown, faMobileAlt, faGlobe, faBook, faFileImport, faBell } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import AuthService from './msal'
import moment from 'moment'
import VueGtag from 'vue-gtag'
import NotificationsMobile from './notifications-mobile'
const locales = require('./config/locales')
const env = process.env.NODE_ENV || 'development'
let alukEnv = 'development'
if (process.env.NODE_ENV === 'production') {
  alukEnv = 'production'
}
console.log('-------------------------------')
console.log('*** web environment ***')
console.log('env :')
console.log(env)
console.log('alukEnv :')
console.log(alukEnv)
console.log('production :')
console.log(process.env.production)
console.log('NODE_ENV :')
console.log(process.env.NODE_ENV)
console.log('-------------------------------')

library.add(faUserCircle, faDraftingCompass, faArrowRight, faArrowLeft, faArrowCircleRight, faPhone, faAddressCard, faClock, faMobile, faSignOutAlt, faTrashAlt, faPlus, faChevronRight, faChevronUp, faChevronDown, faMobileAlt, faGlobe, faBook, faFileImport, faBell)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.prototype.$AuthService = new AuthService()

Vue.prototype.$moment = moment

if (Vue.prototype.$AuthService.getUser() !== null) {
  new NotificationsMobile().initServiceWorker(Vue.prototype.$AuthService.getUser().accountIdentifier)
  Vue.prototype.$language = require('./script/lang').default
}

// Injecte des scripts GA4 dans la balise head de l'App
if (Vue.prototype.$AuthService.getUser() !== null && alukEnv === 'production' &&
  ['BEL', 'FRA', 'GBR', 'ITA', 'NLD'].includes(Vue.prototype.$AuthService.getUser().idToken.extension_BusinessUnitCode)) {
  const ga4Id = locales[Vue.prototype.$AuthService.getUser().idToken.extension_BusinessUnitCode].analytics['productionga4']

  const scriptWithURL = document.createElement('script')
  scriptWithURL.type = 'text/javascript'
  scriptWithURL.src = 'https://www.googletagmanager.com/gtag/js?id=' + ga4Id

  const scriptWithoutUrl = document.createElement('script')
  scriptWithoutUrl.type = 'text/javascript'
  scriptWithoutUrl.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', ` + ga4Id + `);
  `
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(scriptWithURL)
  head.appendChild(scriptWithoutUrl)
}

new Vue({
  router,
  render: function (h) {
    if (Vue.prototype.$AuthService.getUser()) {
      return h(App)
    }
    return Vue.prototype.$AuthService.login()
  }
}).$mount('#app')

Vue.use(VueGtag, {
  bootstrap: false
}, router)

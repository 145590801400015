import $ from 'jquery'
const common = require('../app.js')
common.setInstance()

export default {
  name: 'userSettings',
  async mounted () {
    var token = await this.getUsersToken()
    await common.instance.get('/azuread/user/role/' + common.getCustomerId(), { params: { 'token': token.data.token } }).then(response => {
      if (response.data.result.extension_dc0c4a4b7144400baeb9139145e5760e_NotificationsSettings !== undefined) {
        this.items = response.data.result.extension_dc0c4a4b7144400baeb9139145e5760e_NotificationsSettings
        this.items = JSON.parse(this.items.replace(/'/g, '"'))
      }
    }).catch(error => {
      return this.cb(error)
    })
    if (navigator.platform === 'iPhone' || navigator.platform === 'iPad') {
      this.showPush = 'false'
    }
  },
  data () {
    return {
      items: {
        newOrder: {
          on: true,
          email: true,
          web: true,
          push: true
        },
        deliveryUpdate: {
          on: true,
          email: true,
          web: true,
          push: true
        },
        newCustomerServiceContact: {
          on: true,
          email: true,
          web: true,
          push: true
        }
      },
      showPush: 'true'
    }
  },
  methods: {
    async updateNotificationsSettings (event) {
      this.updateDataItems(event.target.name)
      let token = await this.getUsersToken()
      this.patchUsersNotificationsSettings(token.data.token)
    },
    getUsersToken () {
      return common.getUsersToken()
    },
    updateDataItems (inputName) {
      if (inputName.includes('.on')) {
        inputName = inputName.split('.')[0]
        this.items[inputName].email = this.items[inputName].web = !this.items[inputName].on
        if (this.showPush) {
          this.items[inputName].push = !this.items[inputName].on
        }
      } else {
        this.setCurrentSwitchValue(inputName)
      }
    },
    setCurrentSwitchValue (inputName) {
      let currentInputName = inputName.split('.')[0]
      let currentInputCheck = inputName.split('.')[1]
      this.items[currentInputName].on = false
      if ((!this.items[currentInputName][currentInputCheck] && this.items[currentInputName].web === true) ||
        (!this.items[currentInputName][currentInputCheck] && this.items[currentInputName].email === true)) {
        this.items[currentInputName].on = true
      }
    },
    async patchUsersNotificationsSettings (token) {
      await common.instance.patch('/azuread/users/modify/notificationssettings', { params: {
        'token': token,
        'extension_dc0c4a4b7144400baeb9139145e5760e_NotificationsSettings': this.items,
        'id': common.getCustomerId() }
      }).then(response => {
      }).catch(error => {
        return this.cb(error)
      })
    },
    cb (error) {
      console.log('Error in userSettings.js', error)
    },
    dropdownOrders () {
      common.toggleClasses($('.dropdown-order'), $('.orders-chevron-up'), $('.orders-chevron-down'))
    },
    dropdownDate () {
      common.toggleClasses($('.dropdown-date'), $('.date-chevron-up'), $('.date-chevron-down'))
    },
    dropdownContact () {
      common.toggleClasses($('.dropdown-contact'), $('.contact-chevron-up'), $('.contact-chevron-down'))
    }
  }
}

export default {
  name: 'SearchBar',
  props: {
    placeHolder: String,
    content: String,
    redirect: Boolean,
    whereSearch: String,
    emitAtWatch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: ''
    }
  },
  mounted () {
    if (this.content) {
      this.search = this.content
    }
  },
  watch: {
    search (newValue) {
      if (this.emitAtWatch) {
        this.$emit('searchUpdated', newValue)
      }
    }
  },
  methods: {
    cleanSearch () {
      this.search = ''
      if (this.emitAtWatch) {
        this.$emit('searchUpdated', this.search)
      }
    },

    // Permet de lancer une recherche
    // Si la recherche est effectuée sur une autre page que "search", il y a d'abord une redirection
    // $emit permet de lancer l'évènement "startSearch" au parent
    sendSearch () {
      if (this.search.length > 0) {
        if (this.redirect) {
          this.redirectToSearch()
        } else {
          this.$emit('starSearch', this.search)
        }
      }
    },
    redirectToSearch () {
      let searchParams = { q: this.search }
      let businessUnitCode = this.$route.query.BU
      let customerCode = this.$route.query.CC
      if (businessUnitCode !== undefined && customerCode !== undefined) {
        searchParams.bu = businessUnitCode
        searchParams.cc = customerCode
      }
      if (this.$route.query.Role !== undefined) {
        searchParams.role = this.$route.query.Role
      }
      this.$router.push({ name: 'search' + this.whereSearch, query: searchParams, params: searchParams })
    }
  },
  computed: {
    isempty () {
      return this.search.length > 0
    }
  }
}

import $ from 'jquery'

export default {
  name: 'SearchBarInterfaceInterne',
  props: {
    content: String
  },
  data () {
    return {
      search: ''
    }
  },
  mounted () {
    if (this.content) {
      this.search = this.content
    }
  },
  methods: {
    cleanSearch () {
      this.search = ''
      if (this.$route.params.where === 'all') {
        this.$root.$emit('searchClient', this.search)
        return
      }
      $('.card-body').show()
      $('.client-bloc').show()
    },
    searchClient () {
      if (this.$route.params.where === 'all') {
        this.$root.$emit('searchClient', this.search)
        return
      }
      let inputSearch = this.search.toLowerCase()
      if (inputSearch === '') {
        this.cleanSearch()
        $('.header-card').hide()
        return
      }
      $('.client-bloc').each(function () {
        let find = false
        $(this).find('.customerCompany, .customerCode').each(function () {
          if (this.innerHTML.toLowerCase().includes(inputSearch.toLowerCase())) {
            find = true
          }
        })
        if (!find) {
          $(this).hide()
        } else {
          $(this).show()
        }
      })
    }
  },
  computed: {
    isempty () {
      return this.search.length > 0
    }
  },
  watch: {
    $route () {
      this.search = ''
      this.where = this.$route.params.where
    }
  }
}

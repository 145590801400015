// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import OrdersService from '@/views/customer-service/OrdersService.vue'
const common = require('../app.js')

export default {
  name: 'menu-tab',
  components: {
    Menu,
    OrdersService
  },
  mounted () {
    if (common.isCustomerService(this.$route.query.BU, this.$route.query.CC, common.getCustomerRole())) {
      this.customerService = true
      this.businessUnitCode = this.$route.query.BU
      this.customerCode = this.$route.query.CC
      if (this.$route.query.Role) this.role = this.$route.query.Role
    }
  },
  data () {
    return {
      customerService: false,
      businessUnitCode: '',
      customerCode: '',
      role: ''
    }
  }
}

// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue'
import $ from 'jquery'
const common = require('../app.js')
const helpers = require('../helpers.js')
common.setInstance()

let items = []
let currentNotif = []

export default {
  name: 'notifications',
  components: {
    SearchBar
  },
  async mounted () {
    await this.init()
  },
  data () {
    return {
      items: items,
      offset: 10,
      numberNotifications: 0,
      currentNotif: currentNotif,
      customer_name: common.getCustomerName(),
      selected: this.$route.params.selected,
      allSelected: false,
      indeterminate: false,
      notificationSelected: [],
      customerContact: {}
    }
  },
  methods: {
    async init () {
      let indexSelected = 0
      items.splice(0, items.length)
      common.instance.get('/notifications/lastMonth/10').then(response => {
        this.numberNotifications = response.data.numberNotifications
        this.datas = response.data.notifications
        this.datas.forEach((notification) => {
          this.setNotifications(notification)
        })
        if (this.selected) {
          items.forEach(item => {
            if (item.key === parseInt(this.selected)) {
              indexSelected = items.indexOf(item)
            }
          })
        }
        this.$nextTick(() => {
          this.showNotif(items[indexSelected])
          let elementSelected = $('.notif-preview[data-key=' + items[indexSelected].userNotifKey + ']')
          let overflowScroll = $('.list-notifs')
          overflowScroll.scrollTop(overflowScroll.scrollTop() + elementSelected.position().top - overflowScroll.height() / 2 + elementSelected.height() / 2)
        })
      })
      this.customerContact = await common.getContact(this.$route, 'Primary')
    },
    showNotif (notification) {
      this.currentNotif = notification
      $('.notif-preview').removeClass('active')
      $('.notif-preview[data-key=' + notification.userNotifKey + ']').addClass('active')
      if (!notification.IsNotified) {
        this.setIsNotified(notification)
        notification.IsNotified = true
      }
    },
    setNotifications (notification) {
      notification.Notification.IsNotified = notification.IsNotified
      notification.Notification.UserNotificationKey = notification.UserNotificationKey
      items = helpers.formatNotification(notification.Notification, items)
    },
    setIsNotified (notification) {
      this.changeStatusNotification(notification, 'notified', 'add', 'reduceNotificationUnread')
    },
    setUnread (notification) {
      this.changeStatusNotification(notification, 'unnotified', 'remove', 'addNotificationUnread')
    },
    // Change the status of the notification
    changeStatusNotification (notification, urlDirection, addOrRemove, emit) {
      let key = this.getKeyOfNotification(notification)
      common.instance.put(`/notifications/${urlDirection}/${key}`).then(response => {
        if (response.status === 200) {
          addOrRemove === 'remove'
            ? $('.notif-preview[data-key=' + key + ']').removeClass('isnotified')
            : $('.notif-preview[data-key=' + key + ']').addClass('isnotified')
        }
      })
      this.$root.$emit(emit)
    },
    redirectDetailNotif (notification) {
      this.$router.push({ name: 'detailNotification', params: { notification: notification } })
    },
    redirectToOrder (notif) {
      this.$router.push({ path: '/orders/active/' + notif.alukNumber })
    },
    toggleAll (checked) {
      this.notificationSelected = []
      if (checked) {
        this.items.forEach((item) => {
          this.notificationSelected.push(item.userNotifKey)
        })
      }
    },
    // Change the status of the notifications selected
    changeStatusNotificationsSelected (unreadOrNotified) {
      this.notificationSelected.forEach((notif) => {
        unreadOrNotified === 'unread' ? this.setUnread(notif) : this.setIsNotified(notif)
        this.items.forEach((item) => {
          if (item.userNotifKey === notif) {
            item.IsNotified = !(unreadOrNotified === 'unread')
          }
        })
      })
      this.checked = false
      this.indeterminate = false
      this.notificationSelected = []
    },
    loadNotifs () {
      this.offset = this.offset + 10
      common.instance.get('/notifications/lastMonth/' + this.offset).then(response => {
        this.numberNotifications = response.data.numberNotifications
        let newNotifs = response.data.notifications
        newNotifs.forEach((notification) => {
          this.setNotifications(notification)
        })
      })
    },
    deleteNotification (item, allDeleted) {
      let id = allDeleted === true ? item : item.userNotifKey
      common.instance.put(`/notifications/deleted/${id}`).then(response => {
        if (response.status === 200) {
          const indexNotification = this.items.indexOf(item)
          this.items.splice(indexNotification, 1)
          this.showNotif(this.items[indexNotification])
        }
      })
    },
    deleteAllNotifications () {
      items.forEach(item => {
        this.deleteNotification(item)
      })
      $('#deleteNotifs').modal('hide')
      this.loadNotifs()
    },
    async deleteNotificationsSelected () {
      this.notificationSelected.forEach((notif) => {
        this.deleteNotification(notif, true)
      })
      await this.init()
    },
    // Check if the notification is type object
    getKeyOfNotification (notification) {
      if (typeof notification === 'object') {
        return notification.userNotifKey
      }
      return notification
    }
  },

  watch: {
    notificationSelected () {
      if (this.notificationSelected.length > 0) {
        if (this.notificationSelected.length === this.items.length) {
          this.allSelected = true
          this.indeterminate = false
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      } else {
        this.indeterminate = false
        this.allSelected = false
      }
    }
  }
}

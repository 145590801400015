// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue'
import SearchBarInterfaceInterne from '@/components/SearchBarInterfaceInterne.vue'
import SearchBarClaims from '@/components/SearchBarClaims.vue'
import SearchOrderLines from '@/views/search/SearchOrderLines.vue'

export default {
  name: 'Menu',
  components: {
    SearchBar,
    SearchBarInterfaceInterne,
    SearchBarClaims,
    SearchOrderLines
  },
  data () {
    return {
      whereIAm: '',
      placeHolderSearch: '',
      checked: false
    }
  },
  props: {
    firstTab: String,
    secondTab: String,
    linkFirstTab: String,
    linkSecondTab: String,
    placeHolder: String,
    customerService: String,
    claimsSearch: String
  },
  mounted () {
    this.placeHolderSearch = this.placeHolder
    this.actualRoute()
    this.$root.$on('check-changed', (newState) => {
      this.checked = newState
    })
  },
  methods: {
    classActiv () {
      return {
        'router-link-exact-active': !this.$props.secondTab
      }
    },
    actualRoute () {
      let str = this.$router.currentRoute.path
      let parts = str.split('/')
      parts.shift()
      for (let index = 0; index < parts.length; index++) {
        parts[index] = parts[index].charAt(0).toUpperCase() + parts[index].slice(1)
      }
      this.whereIAm = parts[0]
      if (parts[1] && parts[1].length > 1) {
        this.whereIAm = parts[1] + parts[0]
      }
    }
  }
}

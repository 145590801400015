import { fieldsClaims } from '../fields.js'
const claimsHelper = require('./claims.helper')
const common = require('../app.js')

common.setInstance()

let items = []

export default {
  name: 'completedClaims',
  async mounted () {
    common.instance.get('/claims').then(response => {
      this.items = claimsHelper.fetchClaims(response.data.result, 'completed')
    })
  },
  data () {
    return {
      items: items,
      fields: fieldsClaims
    }
  },
  methods: claimsHelper.setClaimsMethods()
}

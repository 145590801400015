import { fieldsCompletedOrder, fieldsCompletedOrderFR } from '../fields.js'
import SearchBar from '@/components/SearchBar.vue'
import SearchOrderLines from '@/views/search/SearchOrderLines.vue'
import SwitchOrderToOrderLines from '@/components/SwitchOrderToOrderLines.vue'
import { GetLocalStorage } from '@/script/helpers'
const ordersHelpers = require('./orders.helpers.js')
const common = require('../app.js')
common.setInstance()

let items = []

export default {
  name: 'completedOrder',
  components: {
    SearchBar,
    SearchOrderLines,
    SwitchOrderToOrderLines
  },
  mounted () {
    items.splice(0, items.length)
    let url = ordersHelpers.buildApiUrl('completed', this)
    let businessUnitCode = this.$route.query.BU
    let customerCode = this.$route.query.CC
    if (businessUnitCode !== undefined && customerCode !== undefined) {
      url += '/BU/' + businessUnitCode + '/CC/' + customerCode
    }
    let self = this
    getCompletedOrders(url, this, 'completed').then(function () {
      if (self.items.length < self.ordersPerPage) {
        self.viewMoreButtonVisible = false
      }
    })
  },
  data () {
    return {
      fields: common.getCustomerBusinessUnitCode() !== 'FRA' ? fieldsCompletedOrder : fieldsCompletedOrderFR,
      items: items,
      ticketOrderCode: '',
      ticketSubject: '',
      ticketMessage: '',
      previewImage: null,
      viewMoreButtonVisible: true,
      messageNoOrderVisible: false,
      offset: 0,
      ordersPerPage: 100,
      orderBy: GetLocalStorage('orderCompletedOrderBy'),
      sort: GetLocalStorage('orderCompletedSort'),
      sortBy: GetLocalStorage('orderCompletedKey'),
      sortDesc: GetLocalStorage('orderCompletedSort') === 'desc',
      noLocalSorting: true,
      checked: false
    }
  },
  methods: {
    ...ordersHelpers.setOrdersMethods('completed'),
    viewMore () {
      this.offset += this.ordersPerPage + 2
      let url = ordersHelpers.buildApiUrl('completed', this)
      let businessUnitCode = this.$route.query.BU
      let customerCode = this.$route.query.CC
      if (businessUnitCode !== undefined && customerCode !== undefined) {
        url += '/BU/' + businessUnitCode + '/CC/' + customerCode
      }
      getCompletedOrders(url, this)
    },
    handleCheckChange (value) {
      this.checked = value
    }
  }
}

async function getCompletedOrders (url, self) {
  ordersHelpers.makeCallAndSetFrontVariations(url, self, 'completed', true, 'orderStatus')
}

const common = require('./app.js')

export default {
  name: 'footerMobile',
  data () {
    return {
      hasClaims: common.getCustomerLocale().claims,
      customer_role: common.getCustomerRole()
    }
  }
}

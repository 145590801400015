let view = null

export function setView (targetView) {
  if (typeof targetView !== 'undefined') {
    localStorage.view = targetView
  } else {
    view = getView()
  }
}

export function getView () {
  let view = 'Orders'
  if (localStorage.view) {
    view = localStorage.view
  } else if (localStorage.token && JSON.parse(localStorage.token).idTokenClaims) {
    view = 'Orders'
  }
  return view
}

setView()

export default view

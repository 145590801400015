import { fieldsActiveOrder, fieldsCompletedOrder } from '../fields.js'
import language from '@/script/lang'
import { setView } from '../switchView'
import { GetLocalStorage, setLocalStorage } from '@/script/helpers'
const helpers = require('../helpers.js')
const common = require('../app.js')

let itemOrders = []

export default {
  name: 'search-orders',
  props: {
    research: String,
    type: String,
    checked: Boolean
  },
  mounted () {
    this.searchOrder(this.research)
  },
  data () {
    let csRole = this.$route.query.role ? this.$route.query.role : ''
    if (this.$AuthService.getUser().idTokenClaims.extension_Role === 'classic' || csRole === 'classic') {
      delete fieldsActiveOrder[3]
    } else {
      let currency = common.getCustomerLocale().currency === 'GBP' ? '£' : '€'
      fieldsActiveOrder[3].label = language.total_gross + ' (' + currency + ')'
    }
    return {
      items: {
        orders: itemOrders,
        fieldsCompletedOrder: fieldsCompletedOrder,
        fieldsActiveOrder: fieldsActiveOrder
      },
      orderBy: GetLocalStorage('searchOrderBy'),
      sort: GetLocalStorage('searchSort'),
      sortBy: GetLocalStorage('searchKey'),
      sortDesc: GetLocalStorage('searchSort') === 'desc',
      noLocalSorting: true
    }
  },
  computed: {
    getField () {
      if (this.type === 'active') {
        return this.items.fieldsActiveOrder
      } else {
        return this.items.fieldsCompletedOrder
      }
    }
  },
  methods: {
    // Selon le statut de l'order, redirige vers une page détaillée
    onRowOrdersClicked (order) {
      this.$router.push({ path: '/orders/' + this.type + '/' + order.ref })
    },

    // Fonction appelée lors du $emit "starSearch" dans searchBar.js
    // Lance un appel vers l'api pour recevoir une liste d'orders
    // value : valeur du text de la searchBar
    async searchOrder (value) {
      let url = 'orders/search/' + this.type + '/' + value.trim() + '/0/orderBy/' + this.orderBy + '/' + this.sort
      let userRole = common.getCustomerRole()
      if (['customerservice', 'sales'].includes(userRole)) {
        url += '?businessUnitCode=' + this.$route.query.bu + '&customerCode=' + this.$route.query.cc
      }
      await this.$router.push({ query: { q: value } }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
      await common.getApiCall(url, this.type + 'Orders').then((data) => {
        this.handleCheckChange()
        this.items.orders = helpers.transformClassStatus('order', data, 'orderStatusReasonCode')
      })
    },
    async onHeaderClicked (key, field) {
      if (field.thAttr) {
        this.sort = field.thAttr.sort
        this.orderBy = field.thAttr.orderBy
        setLocalStorage('searchKey', field.thAttr.key)
        setLocalStorage('searchOrderBy', field.thAttr.orderBy)
        setLocalStorage('searchSort', field.thAttr.sort)
        await this.searchOrder(this.research)
        field.thAttr.sort = this.sort === 'asc' ? 'desc' : 'asc'
      }
    },
    // Fait passer en vue article s'il n'y a pas de commande
    // dans la réponse de la recherche effectuée
    handleCheckChange () {
      if (this.items.orders.length === 0) {
        setView('Articles')
        this.$root.$emit('handleCheckChange', true)
      }
    }
  },
  watch: {
    // Dès changement de valeur de 'research' => lance une recherche
    research () {
      this.searchOrder(this.research)
    },
    type () {
      this.searchOrder(this.research)
    }
  }
}

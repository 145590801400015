// @ is an alias to /src
import Menu from '@/components/Menu.vue'
const common = require('./app.js')
const members = require('./members.js').default
const membersMethods = members.methods
common.setInstance()

export default {
  name: 'menu-tab',
  components: {
    Menu
  },
  created () {
    this.items.currentUserRole = this.$AuthService.getUser().idToken.extension_Role
  },
  async mounted () {
    this.cleanInviteForm()
    var token = await this.getUsersToken()
    var businessUnitCode = await common.getCustomerBusinessUnitCode()
    this.items.currentUserEmail = await common.getCustomerEmail()
    let params = {
      'token': token.data.token,
      'customerCode': '',
      'businessUnitCode': businessUnitCode,
      'roles': ['customerservice', 'sales']
    }
    await common.instance.get('/azuread/users/listing', { params: params }).then(response => {
      this.items.users = response.data.result.value
    }).catch(error => {
      return this.cb(error)
    })
  },
  data () {
    return members.data()
  },
  methods: {
    async inviteNewUser () {
      this.$root.$emit('bv::hide::modal', 'addMember')
      var token = await this.getUsersToken()
      let isNewUser = this.checkIfIsNewUser()
      if (!isNewUser) {
        this.cb('There is already an account for ' + this.modal.email)
      } else {
        this.items.selectedUser.userType = this.modal.role
        this.items.selectedUser.otherMails[0] = this.modal.email
        this.items.selectedUser.givenName = this.modal.firstName
        this.items.selectedUser.surname = this.modal.lastName
        await common.instance.post('/azuread/users/invite', {
          params: this.prepareParamsForInvite(token)
        }).then(async (result) => {
          await this.sendInvitation(this.modal.email, this.modal.message, this.modal.firstName + ' ' + this.modal.lastName).then(async () => {
            await this.userInGroup(result.data.invite.id, token.data.token).then(() => {
              document.location.reload()
            })
          })
        })
      }
    },
    async sendInvitation (email, message, displayName) {
      await membersMethods.sendInvitation(email, message, displayName)
    },
    async userInGroup (userId, token) {
      await membersMethods.userInGroup(userId, token)
    },
    prepareParamsForInvite (token) {
      return {
        'token': token.data.token,
        'firstName': this.modal.firstName,
        'lastName': this.modal.lastName,
        'email': this.modal.email,
        'message': this.modal.message,
        'role': this.modal.role,
        'displayName': this.modal.firstName + ' ' + this.modal.lastName,
        'customerCode': '',
        'businessUnitCode': this.modal.businessUnitCode
      }
    },
    sendInfo (user) {
      this.items.selectedUser = { ...user }
      this.items.selectedUser.pickedExtensionRole = 'customerservice'
      this.items.selectedUser.extensionCustomerCode = ''
      this.items.selectedUser.extensionBusinessUnitCode = user.extension_dc0c4a4b7144400baeb9139145e5760e_BusinessUnitCode
      this.items.selectedUser.displayMail = user.otherMails[0]
    },
    async deleteUser () {
      var token = await this.getUsersToken()

      await common.instance.delete('/azuread/users/delete', { params: { 'token': token.data.token, 'id': this.items.selectedUser.id } }).then(response => {
        this.updateUsersList('DELETE')
      }).catch(error => {
        return this.cb(error)
      })
    },
    async putUser () {
      var token = await this.getUsersToken()

      this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_Role = 'customerservice'
      this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_CustomerCode = ''
      this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_BusinessUnitCode = this.items.selectedUser.extensionBusinessUnitCode
      this.items.selectedUser.otherMails[0] = this.items.selectedUser.displayMail
      await common.instance.patch('/azuread/users/modify', { params: {
        'token': token.data.token,
        'givenName': this.items.selectedUser.givenName,
        'surname': this.items.selectedUser.surname,
        'displayName': this.items.selectedUser.givenName + ' ' + this.items.selectedUser.surname,
        'otherMail': this.items.selectedUser.displayMail,
        'extensionRole': 'customerservice',
        'extensionCustomerCode': '',
        'extensionBusinessUnitCode': this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_BusinessUnitCode,
        'id': this.items.selectedUser.id }
      }).then(response => {
        this.updateUsersList('PATCH')
      }).catch(error => {
        return this.cb(error)
      })
    },
    updateUsersList (method) {
      var updateIndex = this.items.users.map(function (item) { return item.id }).indexOf(this.items.selectedUser.id)
      if (method === 'PATCH') {
        this.items.users.splice(updateIndex, 1, this.items.selectedUser)
      } else if (method === 'DELETE') {
        this.items.users.splice(updateIndex, 1)
      } else if (method === 'POST') {
        this.items.users.push(this.items.selectedUser)
        this.cleanInviteForm()
      }
    },
    async getUsersToken () {
      return common.getUsersToken()
    },
    closeModalAddMember () {
      this.cleanInviteForm()
      this.$root.$emit('bv::hide::modal', 'addMember')
      this.errorRole = false
    },
    async cleanInviteForm () {
      this.modal = {
        otherMails: [],
        lastName: '',
        firstName: '',
        email: '',
        role: '',
        customerCode: '',
        businessUnitCode: await common.getCustomerBusinessUnitCode(),
        message: this.$language.hello_here_is_the_link_to_create_your_account_on_my_aluk
      }
    },
    checkIfIsNewUser () {
      let emailToAdd = this.modal.email
      let isNewUser = true
      this.items.users.forEach(function (user) {
        if (user.mail === emailToAdd) {
          isNewUser = false
        }
      })
      return isNewUser
    },
    cb (error) {
      membersMethods.cb(error)
    },
    showButton (id) {
      membersMethods.showButton(id)
    }
  }
}

// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import SearchBar from '@/components/SearchBar.vue'
import $ from 'jquery'
import { VueCsvImport } from 'vue-csv-import'
const common = require('./app.js')
common.setInstance()

let modalContent = {
  otherMails: [],
  lastName: '',
  firstName: '',
  email: '',
  customerCode: '',
  businessUnitCode: '',
  role: '',
  message: ''
}

let itemsData = {
  users: {},
  allUsers: {},
  firstList: {},
  selectedUser: {
    otherMails: [],
    pickedExtensionRole: '',
    givenName: '',
    username: '',
    extensionBusinessUnitCode: '',
    extensionCustomerCode: ''
  }
}

export default {
  name: 'menu-tab',
  components: {
    Menu,
    VueCsvImport,
    SearchBar
  },
  created () {
    this.items.currentUserRole = this.$AuthService.getUser().idToken.extension_Role
  },
  async mounted () {
    await this.cleanInviteForm()
    var token = await this.getUsersToken()
    var customerCode = await common.getCustomerCustomerCode()
    var businessUnitCode = await common.getCustomerBusinessUnitCode()
    this.items.currentUserEmail = await common.getCustomerEmail()
    this.userRole = common.getCustomerRole()
    let params = {
      'token': token.data.token,
      'customerCode': customerCode,
      'businessUnitCode': businessUnitCode
    }
    if (this.items.currentUserRole === 'customerservice') { params.role = 'superuser' }
    await common.instance.get('/azuread/users/listing', { params: params }).then(response => {
      this.items.users = response.data.result.value
      this.items.firstList = response.data.result.value
      try {
        const url = new URL(response.data.result['@odata.nextLink'])
        this.showMore = url.searchParams.get('$skiptoken')
      } catch (error) { this.showMore = '' }
    }).catch(error => { return this.cb(error) })
    // Get all users for search
    await common.instance.get('/azuread/users/getall', { params: params }).then(response => {
      this.loadingGetAllUsers = true
      this.items.allUsers = response.data.result.value
    }).catch(error => { return this.cb(error) })
  },
  data () {
    return {
      modal: modalContent,
      items: itemsData,
      showMore: '',
      currentUserEmail: '',
      currentUserRole: '',
      errorRole: false,
      disabledPutUser: true,
      csv: null,
      csvErrors: [],
      showLoader: false,
      showCSVResults: false,
      loadingGetAllUsers: false,
      loadingSearch: false,
      itsASearch: false
    }
  },
  watch: {
    csv: async function (users) {
      this.$refs.csvUpload.remove()
      this.showLoader = true
      const availableRoles = ['SU', 'CU', 'CUWP']
      this.csvErrors = []

      var token = await this.getUsersToken()

      for (const user of users) {
        if (!this.validateEmail(user.email) || !availableRoles.includes(user.userrole)) {
          this.csvErrors.push(user.lastname + ';' + user.firstname + ';' + user.email + ';' + user.customercode + ';' + user.businessunitcode + ';' + user.userrole)
        } else {
          await this.checkIfNewAndSend(token, user)
        }
      }
      this.showLoader = false
      this.showCSVResults = true
    }
  },
  methods: {
    async checkIfNewAndSend (token, user) {
      const roles = { 'SU': 'superuser', 'CU': 'classic', 'CUWP': 'classicwithprices' }
      let isNewUser = await this.checkIfIsNewUser(token, user.email)
      if (isNewUser) {
        user.role = roles[user.userrole]
        user.firstName = user.firstname
        user.lastName = user.lastname
        user.message = this.$language.hello_here_is_the_link_to_create_your_account_on_my_aluk
        user.customerCode = user.customercode
        user.businessUnitCode = user.businessunitcode
        await this.prepareBeforeSend(user, token)
      }
    },
    async inviteNewUser () {
      if (!this.validateEmail(this.modal.email)) {
        return
      }
      if (this.modal.role !== '') {
        var token = await this.getUsersToken()
        let isNewUser = await this.checkIfIsNewUser(token, this.modal.email)
        if (!isNewUser) {
          $('#errorEmailExists').removeClass('d-none')
          this.cb('There is already an account for ' + this.modal.email)
        } else {
          this.$root.$emit('bv::hide::modal', 'addMember')
          await this.prepareBeforeSend(this.modal, token).then(() => {
            document.location.reload()
          })
        }
      } else {
        this.errorRole = true
      }
    },
    async prepareBeforeSend (user, token) {
      this.items.selectedUser.userType = user.role
      this.items.selectedUser.otherMails[0] = user.email
      this.items.selectedUser.givenName = user.firstName
      this.items.selectedUser.surname = user.lastName
      await common.instance.post('/azuread/users/invite', {
        params: this.prepareParamsForInvite(token, user)
      }).then(async (result) => {
        await this.sendInvitation(user.email, user.message, user.firstName + ' ' + user.lastName).then(async () => {
          await this.userInGroup(result.data.invite.id, token.data.token).then(() => { return true })
        })
      })
    },
    async sendInvitation (email, message, displayName) {
      await common.instance.post('/mail/invitation', { params: { email: email, message: message, displayName: displayName } })
        .then(() => { return true })
        .catch(() => { return false })
    },
    async userInGroup (userId, token) {
      await common.instance.post('/azuread/user/group', { params: { user_id: userId, group_id: '7d1dece2-05c9-4068-9f37-4cae4ec705e2', token: token } })
        .then(() => { return true })
        .catch(() => { return false })
    },
    prepareParamsForInvite (token, user) {
      return {
        'token': token.data.token,
        'firstName': user.firstName,
        'lastName': user.lastName,
        'email': user.email,
        'message': user.message,
        'role': user.role,
        'displayName': user.firstName + ' ' + user.lastName,
        'customerCode': user.customerCode,
        'businessUnitCode': user.businessUnitCode
      }
    },
    sendInfo (user) {
      this.items.selectedUser = { ...user }
      this.items.selectedUser.pickedExtensionRole = user.extension_dc0c4a4b7144400baeb9139145e5760e_Role
      this.items.selectedUser.extensionCustomerCode = user.extension_dc0c4a4b7144400baeb9139145e5760e_CustomerCode
      this.items.selectedUser.extensionBusinessUnitCode = user.extension_dc0c4a4b7144400baeb9139145e5760e_BusinessUnitCode
      this.items.selectedUser.displayMail = user.otherMails[0]
      this.disabledPutUser = true
    },
    async deleteUser () {
      var token = await this.getUsersToken()

      await common.instance.delete('/azuread/users/delete', { params: { 'token': token.data.token, 'id': this.items.selectedUser.id } }).then(response => {
        this.updateUsersList('DELETE')
      }).catch(error => {
        return this.cb(error)
      })
    },
    async putUser () {
      var token = await this.getUsersToken()

      this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_Role = this.items.selectedUser.pickedExtensionRole
      this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_CustomerCode = this.items.selectedUser.extensionCustomerCode
      this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_BusinessUnitCode = this.items.selectedUser.extensionBusinessUnitCode
      this.items.selectedUser.otherMails[0] = this.items.selectedUser.displayMail
      await common.instance.patch('/azuread/users/modify', {
        params: {
          'token': token.data.token,
          'givenName': this.items.selectedUser.givenName,
          'surname': this.items.selectedUser.surname,
          'displayName': this.items.selectedUser.givenName + ' ' + this.items.selectedUser.surname,
          'otherMail': this.items.selectedUser.displayMail,
          'extensionRole': this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_Role,
          'extensionCustomerCode': this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_CustomerCode,
          'extensionBusinessUnitCode': this.items.selectedUser.extension_dc0c4a4b7144400baeb9139145e5760e_BusinessUnitCode,
          'id': this.items.selectedUser.id
        }
      }).then(response => {
        this.updateUsersList('PATCH')
      }).catch(error => {
        return this.cb(error)
      })
    },
    updateUsersList (method) {
      var updateIndex = this.items.users.map(function (item) { return item.id }).indexOf(this.items.selectedUser.id)
      if (method === 'PATCH') {
        this.items.users.splice(updateIndex, 1, this.items.selectedUser)
      } else if (method === 'DELETE') {
        this.items.users.splice(updateIndex, 1)
      } else if (method === 'POST') {
        this.items.users.push(this.items.selectedUser)
        this.cleanInviteForm()
      }
    },
    async getUsersToken () {
      return common.getUsersToken()
    },
    closeModalAddMember () {
      this.cleanInviteForm()
      this.$root.$emit('bv::hide::modal', 'addMember')
      this.errorRole = false
    },
    emailChanged () {
      $('#errorEmailExists').addClass('d-none')
      $('#errorEmailFormat').toggleClass('d-none', this.validateEmail(this.modal.email))
    },
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async cleanInviteForm () {
      this.modal = {
        otherMails: [],
        lastName: '',
        firstName: '',
        email: '',
        role: '',
        customerCode: await common.getCustomerCustomerCode(),
        businessUnitCode: await common.getCustomerBusinessUnitCode(),
        message: this.$language.hello_here_is_the_link_to_create_your_account_on_my_aluk
      }
      if (this.items.currentUserRole === 'customerservice') { this.modal.customerCode = '' }
    },
    async checkIfIsNewUser (token, email) {
      return common.instance.post('/azuread/users/exists', {
        params: {
          'email': email,
          'token': token.data.token
        }
      }).then((response) => {
        if (response.data.result.length > 0) {
          return false
        }
        return true
      })
    },
    cb (error) {
      console.log('Error in members.js', error)
    },
    showButton (id) {
      $('.slide[data-idUser=' + id + ']').toggleClass('move-buttons')
      $('.icon-user[data-idUser=' + id + ']').toggleClass('move-infos')
      $('.info-user[data-idUser=' + id + ']').toggleClass('move-infos')
    },
    changeDisabledPutUser () {
      this.disabledPutUser = false
    },
    reloadPage () {
      document.location.reload()
    },
    async viewMore () {
      var token = await this.getUsersToken()
      var customerCode = await common.getCustomerCustomerCode()
      var businessUnitCode = await common.getCustomerBusinessUnitCode()
      this.items.currentUserEmail = await common.getCustomerEmail()
      let params = {
        'token': token.data.token,
        'customerCode': customerCode,
        'businessUnitCode': businessUnitCode
      }
      if (this.showMore !== '') {
        params.skiptoken = this.showMore
      }
      if (this.items.currentUserRole === 'customerservice') {
        params.role = 'superuser'
      }
      await common.instance.get('/azuread/users/listing', { params: params }).then(response => {
        this.items.users = [...this.items.users, ...response.data.result.value]
        try {
          const url = new URL(response.data.result['@odata.nextLink'])
          this.showMore = url.searchParams.get('$skiptoken')
        } catch (error) {
          this.showMore = ''
        }
      }).catch(error => {
        return this.cb(error)
      })
    },
    searchUser (search) {
      let resultSearch = []
      this.loadingSearch = true
      if (search.length >= 2 && this.loadingGetAllUsers === true) {
        resultSearch = this.items.allUsers.filter(function (item) {
          if (item.displayName) {
            return item.displayName.toLowerCase().includes(search.toLowerCase())
          }
          if (item.mail) {
            return item.mail.toLowerCase().includes(search.toLowerCase())
          }
        })
        this.items.users = resultSearch
        this.itsASearch = true
      } else {
        this.goBackList()
      }
      this.loadingSearch = false
    },
    async goBackList () {
      this.items.users = this.items.firstList
      this.itsASearch = false
    }
  }
}

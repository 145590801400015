const common = require('../app.js')
common.setInstance()

export default {
  name: 'detailNotifications',
  data () {
    return {
      notification: this.$route.params.notification,
      customer_name: common.getCustomerName(),
      prevRoute: '',
      customerContact: {}
    }
  },
  async mounted () {
    this.customerContact = await common.getContact(this.$route, 'Primary')
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  methods: {
    redirectToPrevPage (path) {
      this.$router.push({ path: path })
    },
    redirectToOrder (notif) {
      this.$router.push({ path: '/orders/active/' + notif.alukNumber })
    }
  }
}

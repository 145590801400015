// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue'
import SearchNotifications from '@/views/search/SearchNotifications.vue'
import SearchOrders from '@/views/search/SearchOrders.vue'
import SearchOrderLines from '@/views/search/SearchOrderLines.vue'
import SwitchOrderToOrderLines from '@/components/SwitchOrderToOrderLines.vue'
export default {
  name: 'Search',
  components: {
    SearchBar,
    SearchNotifications,
    SearchOrders,
    SearchOrderLines,
    SwitchOrderToOrderLines
  },
  props: {
    q: String
  },
  data () {
    return {
      query: '',
      research: '',
      toRoute: '',
      typeOrder: this.$router.currentRoute.name.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase().split('-')[1],
      checked: false
    }
  },
  beforeMount () {
    if (this.$route.query.q) {
      this.research = this.$route.query.q
    }
    if (this.research) {
      this.query = '?q=' + this.research
      if (this.$route.query.bu) {
        this.query += '&bu=' + this.$route.query.bu
      }
      if (this.$route.query.cc) {
        this.query += '&cc=' + this.$route.query.cc
      }
    }
    this.toRoute = this.$router.currentRoute.name
  },

  // Permet de changer d'onglet en mettant à jour la valeur de this.toRoute
  beforeRouteUpdate (to, from, next) {
    this.toRoute = to.name
    this.typeOrder = this.toRoute.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase().split('-')[1]
    next()
  },
  computed: {
    currentRoute () {
      return this.toRoute
    }
  },
  methods: {
    // Fonction lancée lors de l'appel $emit de l'enfant
    goSearch (value) {
      this.research = value
      this.query = '?q=' + this.research
      if (this.$route.query.bu) {
        this.query += '&bu=' + this.$route.query.bu
      }
      if (this.$route.query.cc) {
        this.query += '&cc=' + this.$route.query.cc
      }
    },
    handleCheckChange (value) {
      this.checked = value
    }
  }
}

const locales = ['BEL', 'CHN', 'FRA', 'GBR', 'IND', 'ITA', 'MEA', 'NLD', 'PLZ']
const configs = Object.fromEntries(
  locales.map(locale => [locale, require(`./config${locale}.json`)])
)

const { BEL, CHN, FRA, GBR, IND, ITA, MEA, NLD, PLZ } = configs

const LOCALES_LABEL = {
  'Dutch': 'Nederlands',
  'English': 'English',
  'EnglishFR': 'English',
  'French': 'Français',
  'FrenchBE': 'Français'
}

const LOCALES_SHORT_LABEL = {
  'Dutch': 'NDL',
  'English': 'ENG',
  'EnglishFR': 'ENG',
  'French': 'FR',
  'FrenchBE': 'FR'
}

export { LOCALES_LABEL, LOCALES_SHORT_LABEL, BEL, CHN, FRA, GBR, IND, ITA, MEA, NLD, PLZ }

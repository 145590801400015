import language from './lang'

const fieldsActiveOrder = [
  { key: 'ref', label: language.aluk_reference, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'ref', orderBy: 'OrderCode', sort: 'asc' }, sortable: true },
  { key: 'customerRef', label: language.customer_reference, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'customerRef', orderBy: 'CustomerReference', sort: 'asc' }, sortable: true },
  { key: 'orderDate', label: language.order_date, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'orderDate', orderBy: 'OrderDateTime', sort: 'asc' }, sortable: true },
  { key: 'totalGross', label: language.total_gross, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'totalGross', orderBy: 'OrderTotalAmount', sort: 'asc' }, sortable: true },
  { key: 'deliveryDate', label: language.estimated_completion_date, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'deliveryDate', orderBy: 'OrderNextDeliveryDateTime', sort: 'asc' }, sortable: true },
  { key: 'orderStatusReasonCode', label: language.estimated_delivery_status, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'orderStatusReasonCode', orderBy: 'OrderStatusReasonCode', sort: 'asc' }, sortable: true }
]

const fieldsActiveOrderFR = [ ...fieldsActiveOrder, { key: 'remainder', label: language.remainder, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'remainder', orderBy: 'Remainder', sort: 'asc' }, sortable: true }
]

const fieldsCompletedOrder = [
  { key: 'ref', label: language.aluk_reference, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'ref', orderBy: 'OrderCode', sort: 'asc' }, sortable: true },
  { key: 'customerRef', label: language.customer_reference, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'customerRef', orderBy: 'CustomerReference', sort: 'asc' }, sortable: true },
  { key: 'orderDate', label: language.order_date, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'orderDate', orderBy: 'OrderDateTime', sort: 'asc' }, sortable: true }
]

const fieldsCompletedOrderFR = [ ...fieldsCompletedOrder,
  { key: 'orderStatusReasonCode', label: language.order_status, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'orderStatusReasonCode', orderBy: 'OrderStatusReasonCode', sort: 'asc' }, sortable: true },
  { key: 'remainder', label: language.remainder, thClass: 'align-middle', tdClass: 'align-middle', thAttr: { key: 'remainder', orderBy: 'Remainder', sort: 'asc' }, sortable: true }
]

const detailField = [
  { key: 'code', label: language.item_code, thClass: 'align-middle' },
  { key: 'description', label: language.description, thClass: 'align-middle' },
  { key: 'color1', label: language.color + ' 1', thClass: 'align-middle' },
  { key: 'color2', label: language.color + ' 2', thClass: 'align-middle' },
  { key: 'color3', label: language.color + ' 3', thClass: 'align-middle' },
  { key: 'length', label: language.length, thClass: 'align-middle', tdClass: '' },
  { key: 'unit', label: language.unit, thClass: 'align-middle', tdClass: '' },
  { key: 'quantity', label: language.quantity, thClass: 'align-middle', tdClass: '' },
  { key: 'allocatedQuantity', label: language.quantity_allocated, thClass: 'align-middle', tdClass: '' },
  { key: 'dispatchedQuantity', label: language.quantity_dispatched, thClass: 'align-middle', tdClass: '' },
  { key: 'outstandingQuantity', label: language.quantity_outstanding, thClass: 'align-middle', tdClass: '' },
  { key: 'list', label: language.list_price, thClass: 'align-middle', tdClass: ' price' },
  { key: 'net', label: language.net_price, thClass: 'align-middle', tdClass: ' price' },
  { key: 'total', label: language.total_net, thClass: 'align-middle', tdClass: ' price' },
  { key: 'delivery', label: language.estimated_delivery_date, thClass: 'align-middle' }
]

const fieldsClaims = [
  { key: 'ref', label: language.query_reference, thClass: 'align-middle', tdClass: 'ref', sortable: true },
  { key: 'alukNumber', label: language.aluk_number, thClass: 'align-middle', tdClass: 'alukNumber', sortable: true },
  { key: 'code', label: language.item_code, thClass: 'align-middle', tdClass: 'code', sortable: true },
  { key: 'subject', label: language.subject, thClass: 'align-middle', tdClass: 'subject', sortable: true },
  { key: 'status', label: language.status, thClass: 'align-middle', tdClass: 'status', sortable: true },
  { key: 'date', label: language.date_of_query, thClass: 'align-middle', tdClass: 'date', sortable: true }
]

const fieldNotifications = [
  { key: 'notificationRef', label: language.notification_ref, class: 'notificationRef', thClass: 'align-middle', thAttr: { orderBy: '', sort: 'asc' }, sortable: true },
  { key: 'alukRef', label: language.aluk_reference, class: 'alukRef', thClass: 'align-middle', thAttr: { orderBy: '', sort: 'asc' }, sortable: true },
  { key: 'date', label: language.date_of_notification, class: 'date', thClass: 'align-middle', thAttr: { orderBy: '', sort: 'asc' }, sortable: true }
]

const fieldOrderLines = [
  { key: 'code', label: language.item_code, class: 'code', thClass: 'align-middle', thAttr: { key: 'code', orderBy: 'ItemCode', sort: 'asc' }, sortable: true },
  { key: 'description', label: language.description, class: 'description', thClass: 'align-middle', thAttr: { key: 'description', orderBy: 'ItemDescription', sort: 'asc' }, sortable: true },
  { key: 'quantity', label: language.quantity_short, class: 'quantity', thClass: 'align-middle', thAttr: { key: 'quantity', orderBy: 'Quantity', sort: 'asc' }, sortable: true },
  { key: 'total', label: language.total_net, class: 'price', thClass: 'align-middle', thAttr: { key: 'total', orderBy: 'TotalDiscountPrice', sort: 'asc' }, sortable: true },
  { key: 'alukRef', label: language.aluk_reference, class: 'alukRef', thClass: 'align-middle', sortable: false },
  { key: 'customerRef', label: language.customer_reference, class: 'customerRef', thClass: 'align-middle', sortable: false },
  { key: 'orderDate', label: language.order_date, class: 'orderDate', thClass: 'align-middle', sortable: false },
  { key: 'estimatedCompletionDate', label: language.estimated_completion_date, class: 'estimatedCompletionDate', thClass: 'align-middle', thAttr: { key: 'estimatedCompletionDate', orderBy: 'EstimatedDeliveryDate', sort: 'asc' }, sortable: true }
]

export { fieldsActiveOrder, fieldsCompletedOrder, detailField, fieldsClaims, fieldNotifications, fieldsCompletedOrderFR, fieldsActiveOrderFR, fieldOrderLines }

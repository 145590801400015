import Menu from '@/components/Menu.vue'
import Client from '../../views/customer-service/Client.vue'
import MyClients from '../../views/customer-service/MyClients.vue'
const common = require('../app.js')
common.setInstance()

export default {
  name: 'customer-service',
  components: {
    Menu,
    Client,
    MyClients
  },
  data () {
    return {
      where: '',
      items: {
        my: {},
        all: {}
      },
      role: common.getCustomerRole()
    }
  },
  beforeMount () {
    let customerRole = common.getCustomerRole()
    if (customerRole !== 'customerservice' && customerRole !== 'sales') {
      this.$router.push({ path: '/home' })
    }
  },
  created () {
    this.where = this.$route.params.where
    if (!['my', 'all'].includes(this.where)) {
      this.$router.push('/customer-service/my')
    }
  },
  watch: {
    $route () {
      this.where = this.$route.params.where
    }
  }
}

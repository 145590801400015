import moment from 'moment'
import language from '../lang'
const common = require('../app.js')
const helpers = require('../helpers')

const claimStatus = {
  'active': ['Open', 'Acknowledged', 'In Review', 'For collection', 'Approved', 'Credit Approval', 'On-Hold', 'Envoyée', 'En cours de traitement', 'Envoyée | Aperto', 'En cours de traitement | In corso'],
  'completed': ['Closed', 'Resolved', 'Rejected', 'Résolue', 'Résolue | Chiuso'],
  'other': ['Archived', 'Deleted']
}

export async function getClaims (statut, callback) {
  common.instance.get('/claims').then(response => {
    let items = this.fetchClaims(response.data.result, statut)
    callback(items)
    return items
  })
}

export function fetchClaims (results, status) {
  let claims = []
  results.forEach(entry => {
    if (claimStatus[status].includes(entry.status.name)) {
      claims.push({
        ref: entry.id,
        status: entry.status.name,
        date: moment(entry.timestamps.create).format('DD/MM/YYYY'),
        code: entry.cdata.ht['aluk_item-code'],
        alukNumber: entry.cdata.ht['aluk_number'],
        subject: entry.subject
      })
    }
  })
  claims = helpers.transformClassStatus('claim', claims, 'status')
  return claims
}

export function setClaimsMethods () {
  return {
    rowClass () {
      return 'claimLine'
    },
    setItems (data) {
      this.items = data
    },
    mySortCompare (itemA, itemB, key) {
      if (key === 'date') {
        let a = itemA[key].split('/')
        let b = itemB[key].split('/')
        a = common.transformDate(a)
        b = common.transformDate(b)
        return a - b
      } else {
        return false
      }
    }
  }
}

export const itemFaultTypes = {
  'damage_bent': language.damage_bent,
  'damage_scratched': language.damage_scratched,
  'damage_dented': language.damage_dented,
  'damage_marked': language.damage_marked,
  'incorrect_price_invoice_discount': language.incorrect_price_invoice_discount,
  'incorrect_delivery_part_number': language.incorrect_delivery_part_number,
  'incorrect_delivery_part_missing': language.incorrect_delivery_part_missing,
  'incorrect_delivery_not_ordered': language.incorrect_delivery_not_ordered,
  'faulty_paint': language.faulty_paint,
  'faulty_rolling': language.faulty_rolling,
  'faulty_dimensions': language.faulty_dimensions,
  'tooling_return': language.tooling_return,
  'tooling_faulty': language.tooling_faulty,
  'request_to_restock_not_required': language.request_to_restock_not_required
}

export const orderFaultTypes = {
  'incorrect_delivery_part_number': language.incorrect_delivery_part_number,
  'incorrect_delivery_not_ordered': language.incorrect_delivery_not_ordered,
  'tooling_return': language.tooling_return,
  'request_to_restock_not_required': language.request_to_restock_not_required
}

export const frenchItemFaultTypes = {
  'accessory_incorrect_delivery': 'Accessoire : livraison incorrecte (quantité erronée, erreur produit,...)',
  'accessory_default': 'Accessoire : défaut',
  'profile_incorrect_delivery': 'Profilé : livraison incorrecte',
  'profile_default': 'Profilé : défaut (mauvaise couleur, dimension, défaut peinture,...)',
  'tooling': 'Outils',
  'other': 'Autre'
}

export const frenchOrderFaultTypes = {
  'incorrect_delivery': 'Livraison incorrecte',
  'default': 'Défaut',
  'commercial_discount': 'Remise commerciale',
  'other': 'Autre'
}

export const italianOrderItemFaultTypes = {
  'error_order': 'Errore di inserimento ordine',
  'error_delivery': 'Errore di consegna',
  'damage_delivery': 'Danno da trasporto/ movimentazione',
  'default_profile_painted': 'Difetto finitura profilati (verniciato)',
  'default_profile_oxidized': 'Difetto finitura profilati (ossidato)',
  'default_profile_sublimated': 'Difetto finitura profilati (sublimato)',
  'default_profile_raw': 'Difetto visivo profilati (grezzo)',
  'default_profile_dimension': 'Difetto dimensionale profilati',
  'default_profile_shape': 'Difetto di forma profilati',
  'default_profile_assembly': 'Difetto di assemblaggio profilati TT',
  'default_profile_other': 'Altro (profilati)',
  'default_accessory_finish': 'Difetto finitura accessori',
  'default_accessory_functional': 'Difetto funzionale accessori',
  'default_accessory_packaging': 'Difetto confezionamento accessori',
  'default_accessory_other': 'Altro (accessori)',
  'default_seal_dimension': 'Difetto dimensionale guarnizione',
  'default_seal_functional': 'Difetto funzionale guarnizione',
  'default_seal_packaging': 'Difetto di imballaggio guarnizione',
  'default_seal_other': 'Altro (guarnizione)'
}

import { detailField } from '../fields.js'
import $ from 'jquery'
const common = require('../app.js')
const claimsHelpers = require('../claims/claims.helper.js')
const helpers = require('../helpers')
const orderHelper = require('../orders/orders.helpers.js')
common.setInstance()

export default {
  name: 'activeOrder',
  async mounted () {
    await this.init()
  },
  data () {
    let fields = this.getLocaleDetailField()
    if (common.getCustomerLocale().claimsActiveOrders) fields.push({ key: 'claims', label: '' })
    return {
      fields: fields,
      hasClaims: common.getCustomerLocale().claimsActiveOrders,
      items: this.items,
      order: this.order,
      prevRoute: '',
      showItemCode: false,
      ticketItemCode: '',
      ticketOrderCode: '',
      ticketMessage: '',
      ticketSubject: '',
      ticketEmail: common.getCustomerEmail(),
      ticketName: common.getCustomerName(),
      previewImage: null,
      claimModalLabel: '',
      claimFaultTypes: [],
      modalInputRequired: false,
      customerContact: {},
      customerRole: common.getCustomerRole(),
      locale: common.getCustomerLocale().orders,
      currency: common.getCustomerLocale().currency === 'GBP' ? '£' : '€',
      customer_bu: common.getCustomerBusinessUnitCode(),
      hideWeight: common.getCustomerLocale().hideWeight,
      hideTotalDetail: common.getCustomerLocale().orders.hideTotalDetail,
      showTotalGross: common.getCustomerLocale().orders.columns.totalGross,
      remainder: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  updated () {
    this.$nextTick(function () {
      this.commentsCheckLoadMore()
    })
  },
  methods: {
    async init () {
      const ref = this.$route.params.id
      common.instance.get('/orders/' + ref).then(response => {
        this.lines = response.data.lines
        this.order = { ...response.data.order }
        this.remainder = response.data.remainder
        this.order.OrderNextDeliveryDateTime = this.$moment(this.order.OrderNextDeliveryDateTime).format('DD/MM/YYYY')
        this.order.OrderDateTime = this.$moment(this.order.OrderDateTime).format('DD/MM/YYYY')
        this.order.comments = response.data.comment
        this.items = []
        this.lines.forEach((line) => {
          var haveUnitPrice = false
          if (line.UnitPrice !== undefined) {
            haveUnitPrice = true
          }
          if (line.OriginalRequestedDate) {
            this.order.OriginalRequestedDate = this.$moment(line.OriginalRequestedDate).format('DD/MM/YYYY')
          }
          line.EstimatedDeliveryDate = this.$moment(line.EstimatedDeliveryDate, 'YYYYMMDD').format('DD/MM/YYYY')
          this.addLineInItems(line)
          if (!haveUnitPrice) {
            this.removePriceFields()
          }
        })
      })
      this.customerContact = await common.getContact(this.$route, 'Primary')
      // If is a customer service, the mail of the customer (not the cs) is send for a ticket
      if (common.isCustomerService(this.$route.query.BU, this.$route.query.CC, common.getCustomerRole())) {
        await this.setClientClaimsMail()
      }
    },
    getLocaleDetailField () {
      let locale = common.getCustomerLocale().orders
      // classic user can't see price columns
      let localeDetailField = this.managePriceColumns()
      for (const [key, value] of Object.entries(locale.columns)) {
        if (!value) delete localeDetailField[localeDetailField.map(e => e.key).indexOf(key)]
      }
      if (common.getCustomerBusinessUnitCode() === 'ITA') {
        localeDetailField[localeDetailField.map(e => e.key).indexOf('color1')].label = 'Colore interno'
        localeDetailField[localeDetailField.map(e => e.key).indexOf('color2')].label = 'Colore esterno'
      }
      return localeDetailField
    },
    managePriceColumns () {
      let result = detailField
      if (common.getCustomerRole() === 'classic' || (common.getCustomerRole() === 'customerservice' && this.$route.query.Role === 'classic')) {
        // Remove price columns
        result = detailField.filter(field => field.tdClass ? !field.tdClass.includes('price') : true)
      }
      return result
    },
    addLineInItems (line) {
      let item = {
        code: line.ItemCode,
        description: line.ItemDescription,
        color1: helpers.formatColor(line.Colour1),
        color2: helpers.formatColor(line.Colour2),
        color3: helpers.formatColor(line.Colour3),
        length: helpers.formatLength(line.Length),
        unit: line.UnitOfMeasureCode,
        quantity: line.Quantity,
        allocatedQuantity: line.NotDispatchedAllocatedQuantity,
        dispatchedQuantity: line.DispatchedQuantity,
        outstandingQuantity: line.NotDispatchedNotAllocatedQuantity,
        list: helpers.formatPrice(line.UnitPrice),
        net: helpers.formatPrice(line.UnitPriceAfterDiscount),
        total: helpers.formatPrice(line.TotalDiscountPrice),
        delivery: {
          date: common.getCustomerLocale().orders.lineEstimatedDeliveryDate ? line.EstimatedDeliveryDate : this.order.OrderNextDeliveryDateTime,
          status: line.OrderStatusReasonCode
        }
      }
      if (common.getCustomerLocale().claimsActiveOrders) item.claims = this.$language.make_a_query
      this.items.push(item)
    },
    removePriceFields () {
      delete detailField[3]
      delete detailField[4]
      delete detailField[5]
    },
    dropdown (type) {
      common.toggleClasses($('.dropdown-' + type), $('.' + type + '-chevron-up'), $('.' + type + '-chevron-down'))
    },
    redirectToPrevPage (path) {
      this.$router.push({ path: path })
    },
    formatPrice (price) {
      return helpers.formatPrice(price)
    },
    formatPriceWithCurrency (price) {
      return helpers.formatPriceWithCurrency(price)
    },
    commentsCheckLoadMore () {
      if (this.$refs.commentsDiv && this.$refs.commentsDiv.scrollHeight === this.$refs.commentsDiv.clientHeight) {
        this.$refs.commentsMoreDiv.remove()
      }

      if (this.$refs.commentsDivMobile && this.$refs.commentsDivMobile.scrollHeight === this.$refs.commentsDivMobile.clientHeight) {
        this.$refs.commentsMoreDivMobile.remove()
      }
    },
    viewMoreComments () {
      this.$refs.commentsDivMobile.classList = []
      this.$refs.commentsDiv.classList = []
      this.$refs.commentsMoreDiv.remove()
      this.$refs.commentsMoreDivMobile.remove()
    },
    sendItemCode (itemCode) {
      this.showItemCode = true
      this.ticketItemCode = itemCode
    },
    hideItemCode () {
      this.ticketItemCode = ''
      this.showItemCode = false
    },
    loadModalTitleAndFaultTypes (orderOrItem) {
      let businessUnit = common.getCustomerBusinessUnitCode()
      if (orderOrItem === 'order') {
        this.claimFaultTypes = businessUnit === 'FRA' ? claimsHelpers.frenchOrderFaultTypes : (businessUnit === 'ITA' ? claimsHelpers.italianOrderItemFaultTypes : claimsHelpers.orderFaultTypes)
        this.claimModalLabel = this.$language.log_an_order_issue
      } else {
        this.claimFaultTypes = businessUnit === 'FRA' ? claimsHelpers.frenchItemFaultTypes : (businessUnit === 'ITA' ? claimsHelpers.italianOrderItemFaultTypes : claimsHelpers.itemFaultTypes)
        this.claimModalLabel = this.$language.log_an_item_or_product_issue
      }
    },
    uploadImage (event) {
      const image = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = event => {
        this.previewImage = event.target.result
      }
    },
    onChangeOption (event) {
      if (event.target.value === 'Incorrect - Price/Invoice/Discount' || event.target.value === 'Request to Restock - Not Required') {
        this.modalInputRequired = true
      } else {
        this.modalInputRequired = false
      }
    },
    postTicket () {
      if (this.modalInputRequired && !this.ticketMessage) {
        alert('Please add any supporting notes')
      } else {
        const emailWhoToSend = common.isCustomerService(this.$route.query.BU, this.$route.query.CC, common.getCustomerRole()) ? this.clientClaimsMail : common.getCustomerEmail()
        common.instance.get('/me').then(response => {
          let alukRef = common.getCustomerBusinessUnitCode() === 'ITA' ? this.order.OrderName : this.order.OrderCode
          let ticketMessage = this.$language.this_ticket_concerns_the_order + ' ' + alukRef + '.\n'
          ticketMessage += '"' + this.ticketMessage + '"'
          common.instance.post('/claims/post', {
            params: {
              'alert': true,
              'autorespond': true,
              'message': ticketMessage,
              'subject': this.ticketSubject,
              'name': common.getCustomerEmail(),
              // topicId is linked to department -- The value '2' is for Italian department
              'topicId': common.getCustomerBusinessUnitCode() === 'FRA' ? '1' : '2',
              'email': emailWhoToSend,
              'aluk_customer-code': common.getCustomerCustomerCode(),
              'aluk_company-name': response.data.customer.CustomerName,
              'aluk_number': alukRef,
              'aluk_item-code': this.ticketItemCode,
              'attachments': [
                { 'image.png': this.previewImage }
              ]
            }
          }).then(res => {
            $('#claim-successfull-creation').addClass('show')
            $('#addClaim').modal('hide')
            this.ticketMessage = ''
            this.ticketSubject = ''
          })
        })
      }
    },
    async setClientClaimsMail () {
      let token = await common.getUsersToken()
      await common.instance.get(`/customers-service/byCustomerCode/${this.$route.query.BU}/${this.$route.query.CC}`, { params: { 'token': token.data.token } }).then(response => {
        this.clientClaimsMail = common.getCustomerEmail()
        if (response.data[0]) {
          if (response.data[0].users[0]) {
            this.clientClaimsMail = response.data[0].users[0].mail
          }
        }
      })
    },
    ...orderHelper.setDetailOrdersMethods()
  },
  watch: {
    ...orderHelper.setDetailOrdersWatch()
  }
}

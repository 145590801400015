
import * as firebase from 'firebase'
const tokenProvider = require('axios-token-interceptor')
const axios = require('axios')
const common = require('../script/app.js')
const instance = axios.create({
  baseURL: '/api'
})
instance.interceptors.request.use(
  tokenProvider({
    getToken: () => {
      return common.getRawIdToken()
    }
  })
)
export default class NotificationsMobile {
  constructor () {
    var firebaseConfig = require('./conf/firebase.json')
    firebase.initializeApp(firebaseConfig)
  }

  initServiceWorker (userId) {
    if (firebase.messaging.isSupported()) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function () {

      }).catch(function (error) {
        console.error('ERROR : ', error)
      })

      firebase.messaging().requestPermission()
        .then(function (token) {
          firebase.messaging().getToken().then(function (token) {
            instance.get('/tokens/oauth2/users')
              .then(function (auth2token) {
                instance.patch('/push-notifications/token', {
                  params: {
                    'auth2token': auth2token.data.token,
                    'deviceToken': token,
                    'id': userId
                  }
                }).then(response => {
                  console.log('DONE :', response)
                })
              })
          })
        })
        .catch(function (err) {
          console.log('Unable to get permission to notify.', err)
        })
    }
  }
}
